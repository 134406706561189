import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/wods", requestOptions);
}

async function _getWod(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/wods" + id, requestOptions);
}

async function _updateWod(
  id,
  description,
  fulldescription,
  qualifytype,
  qualifytype2,
  partner,
  gender1,
  gender2
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      description: description,
      fulldescription: fulldescription,
      qualifytype: qualifytype,
      qualifytype2: qualifytype2,
      partner: partner,
      gender1: gender1,
      gender2: gender2,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/wods/" + id, requestOptions);
}

async function _createWod(
  description,
  fulldescription,
  qualifytype,
  qualifytype2,
  partner,
  gender1,
  gender2
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      description: description,
      fulldescription: fulldescription,
      qualifytype: qualifytype,
      qualifytype2: qualifytype2,
      partner: partner,
      gender1: gender1,
      gender2: gender2,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/wods/", requestOptions);
}

async function _deleteWod(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/wods/" + id, requestOptions);
}

async function _addExerciseToWod(wodId, exerciseId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      wodId: wodId,
      exerciseId: exerciseId,
    }),
  };
  console.log(requestOptions.body);
  return fetch(
    ApiUrlBase + "/wods/" + wodId + "/exercises/" + exerciseId,
    requestOptions
  );
}

async function _deleteExerciseToWod(wodId, exerciseId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(
    ApiUrlBase + "/wods/" + wodId + "/exercises/" + exerciseId,
    requestOptions
  );
}

export const WodService = {
  getAll: _getAll,
  getWod: _getWod,
  updateWod: _updateWod,
  createWod: _createWod,
  deleteWod: _deleteWod,
  addExerciseToWod: _addExerciseToWod,
  deleteExerciseToWod: _deleteExerciseToWod,
};
