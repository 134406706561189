import { useEffect, useRef, useState } from "react";

import { Editor } from "@tinymce/tinymce-react";

import { NotificationReceiverType } from "../../helpers/constants";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Title from "../../components/Common/Title/Title";
import Footer from "../../components/Layout/Footer";
import Loader from "../../components/Layout/Loader";
import Settings from "../../components/Layout/Settings";
import { ComunicationService } from "../../services/comunicationService";
import { TeamsService } from "../../services/teamsService";

const Email = () => {
  const editorRef = useRef(null);
  const sendMail = async () => {
    const response = await ComunicationService.sendEmail(
      subject,
      editorRef.current.getContent(),
      receiverType,
      receiverIds,
      false,
      ""
    );

    if (response.ok) {
      alert("Email de prueba enviado correctamente");
    } else {
      alert("Error al enviar el email de prueba");
    }
  };

  const sendTestMail = async () => {
    const response = await ComunicationService.sendEmail(
      subject,
      editorRef.current.getContent(),
      receiverType,
      receiverIds,
      true,
      testEmail
    );

    if (response.ok) {
      alert("Email de prueba enviado correctamente");
    } else {
      alert("Error al enviar el email de prueba");
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [html, setHtml] = useState("");
  const [testEmail, setTestEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [receiverType, setReceiverType] = useState(1);
  const [receiverIds, setReceiverIds] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const receiverTypes = [
    {
      value: NotificationReceiverType.TEAM_MEMBER,
      label: "Todos los miembros",
    },
    {
      value: NotificationReceiverType.TEAM_CAPTAIN,
      label: "Solo el capitán",
    },
  ];

  useEffect(() => {
    const loadStuff = async () => {
      const templatesResponse = await ComunicationService.getAllTemplates();
      if (templatesResponse.ok) {
        const templatesJson = await templatesResponse.json();
        setTemplates(templatesJson);
        setSelectedTemplate(templatesJson[0].name);
      }
      const teamsResponse = await TeamsService.getAll();
      if (teamsResponse.ok) {
        var teamsJson = await teamsResponse.json();
        // sort by name
        teamsJson = teamsJson
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setAllTeams(teamsJson);
      }
      setIsLoading(false);
    };

    loadStuff();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      setHtml(templates.find((t) => t.name === selectedTemplate).html);
    }
  }, [selectedTemplate]);

  const handleCheckboxChange = (event) => {
    const checkboxId = parseInt(event.target.value);

    // Check if the checkbox is checked or unchecked
    if (event.target.checked) {
      // Checkbox is checked, add it to the array
      setReceiverIds((receiverIds) => [...receiverIds, checkboxId]);
    } else {
      // Checkbox is unchecked, remove it from the array
      setReceiverIds((receiverIds) =>
        receiverIds.filter((id) => id !== checkboxId)
      );
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Comunicaciones", url: "/Comunications" },
                      { title: "Emailing", url: "/Email" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Tipo de destinatario</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <select
                            value={receiverType}
                            className="form-control"
                            onChange={(e) => setReceiverType(e.target.value)}
                          >
                            {receiverTypes.map((rcvType) => (
                              <option value={rcvType.value}>
                                {rcvType.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Equipos</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          {allTeams.map((team) => (
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id={"chk" + team.id}
                                name={"chk" + team.id}
                                onChange={handleCheckboxChange}
                                value={team.id}
                                checked={receiverIds.includes(team.id)}
                              />
                              <label
                                for={"chk" + team.id}
                                className="custom-control-label"
                              >
                                {team.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="card card-primary card-outline">
                      <div className="card-header">
                        <h3 className="card-title">Redactar el email</h3>
                      </div>

                      <div className="card-body">
                        <div className="form-group">
                          <label>Plantilla</label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setSelectedTemplate(e.target.value)
                            }
                          >
                            {templates.map((template) => (
                              <option value={template.name}>
                                {template.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="form-control"
                            placeholder="Asunto:"
                          />
                        </div>
                        <div class="callout callout-info">
                          <h5>Info!</h5>
                          <p>
                            Puedes usar estas variables para personaliza el
                            email:
                          </p>
                          <strong>
                            [Name] [Surname] [FullName] [Email] [TeamName]
                            [BoxName]
                          </strong>
                        </div>
                        <div className="form-group">
                          <Editor
                            apiKey="xe0om92bng3r5gqpljer4fdj1m1mi1vrvtolefcsnz2y53zf"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={html}
                            init={{
                              custom_elements:
                                "html,head,title,meta,link,style,span",
                              height: 500,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "print",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "paste",
                                "help",
                                "wordcount",
                                "code",
                                "link",
                              ],
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic | alignleft aligncenter " +
                                "alignright alignjustify | link | bullist numlist outdent indent | " +
                                "removeformat | code help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                        </div>
                      </div>

                      <div class="alert alert-warning alert-dismissible">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="alert"
                          aria-hidden="true"
                        >
                          ×
                        </button>
                        <h5>
                          <i class="icon fas fa-exclamation-triangle"></i>{" "}
                          Revisa los destinatarios!
                        </h5>
                        El email se va a enviar a{" "}
                        <strong>
                          {receiverType == 1
                            ? "TODOS LOS MIEMBROS"
                            : "EL CAPITÁN"}{" "}
                        </strong>
                        de{" "}
                        <strong>
                          {receiverIds.length === 0
                            ? "TODOS LOS EQUIPOS"
                            : "LOS SIGUIENTES EQUIPOS"}
                        </strong>
                        <br />
                        {receiverIds.length > 0 && (
                          <>
                            <ul>
                              {receiverIds.map((id) => (
                                <li>
                                  {allTeams.find((t) => t.id === id).name}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>

                      <div className="card-footer">
                        <div className="row">
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">@</span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email de prueba"
                                value={testEmail}
                                onChange={(e) => setTestEmail(e.target.value)}
                              />
                              <span className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={sendTestMail}
                                >
                                  <i className="far fa-paper-plane"></i> Enviar
                                  prueba
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="float-right">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={sendMail}
                              >
                                <i className="far fa-envelope"></i> Enviar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );

  return null;
};
export default Email;
