import moment from "moment";

const Timeline = ({ events }) => {
  const eventsByDate = events.reduce((acc, event) => {
    const date = moment(new Date(event.loggedAt + "z")).format("DD/MM/YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  return (
    <div class="timeline">
      {Object.entries(eventsByDate).map(([date, events]) => (
        <>
          <div class="time-label">
            <span class="bg-red">{date}</span>
          </div>
          {events.map((event) => {
            var logTypeIcon = "fas fa-user bg-green";
            var logTypeBgColor = "bg-green";
            switch (event.logType) {
              case 1: // fixture scheduled
                logTypeIcon = "far fa-calendar-alt";
                logTypeBgColor = "bg-blue";
                break;
              case 2: // fixture lineup
                logTypeIcon = "fas fa-users-cog";
                logTypeBgColor = "bg-purple";
                break;
              case 3: // fixture result
                logTypeIcon = "fas fa-trophy";
                logTypeBgColor = "bg-lightblue";
                break;
              case 4: // user login
                logTypeIcon = "fas fa-user-check";
                logTypeBgColor = "bg-green";
                break;
              case 5: // user update
                logTypeIcon = "fas fa-user-cog";
                logTypeBgColor = "bg-maroon";
                break;
              case 6: // team invitation
                logTypeIcon = "fas fa-user-plus";
                logTypeBgColor = "bg-navy";
                break;
            }
            return (
              <div>
                <i class={logTypeIcon + " " + logTypeBgColor}></i>
                <div class="timeline-item">
                  <span class="time">
                    <i class="fas fa-clock"></i>{" "}
                    {moment(new Date(event.loggedAt + "z")).format("HH:mm")}
                  </span>
                  <h3 class="timeline-header no-border">{event.logMessage}</h3>
                </div>
              </div>
            );
          })}
        </>
      ))}

      <div>
        <i class="fas fa-clock bg-gray"></i>
      </div>
    </div>
  );
};

export default Timeline;
