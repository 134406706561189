import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/divisions", requestOptions);
}

async function _updateDivision(id, name, description) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/divisions/" + id, requestOptions);
}

async function _createDivision(name, description) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/divisions/", requestOptions);
}
async function _deleteDivision(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/divisions/" + id, requestOptions);
}

export const DivisionsService = {
  getAll: _getAll,
  updateDivision: _updateDivision,
  createDivision: _createDivision,
  deleteDivision: _deleteDivision,
};
