import Breadcrumbs from "../Breadcrumbs";

const Title = ({ items }) => {
  return (
    <>
      <div className="col-sm-6">
        <h1 className="m-0">{items[items.length - 1].title}</h1>
      </div>
      <div className="col-sm-6">
        <Breadcrumbs items={items} />
      </div>
    </>
  );
};

export default Title;
