import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Loader from "../../components/Layout/Loader";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import { AccountsService } from "../../services/accountsService";

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllAccounts = async () => {
    const response = await AccountsService.getAll();
    if (response.ok) {
      const result = await response.json();
      setAccounts(result);
    } else {
      return {
        success: false,
        message: "No se han podido obtener los usuarios de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllAccounts();
  }, []);

  useEffect(() => {
    if (accounts.length > 0) initDataTables();
  }, [accounts]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblAccounts")) {
      window.$("#tblAccounts").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4],
            className: "text-center",
          },
        ],
      });
    }
  };

  const ban = async (id) => {
    // confirm first
    const confirm = window.confirm(
      "¿Está seguro de bloquear el usuario? A partir de este momento, el usuario no tendrá acceso a las funciones de NCL."
    );

    if (!confirm) return;

    const response = await AccountsService.ban(id);
    if (response.ok) {
      getAllAccounts();
    } else {
      return {
        success: false,
        message: "No se ha podido bloquear el usuario.",
      };
    }
  };

  const unban = async (id) => {
    // confirm first
    const confirm = window.confirm(
      "¿Está seguro de desbloquear el usuario? A partir de este momento, el usuario tendrá acceso a todas las funciones de NCL."
    );

    if (!confirm) return;

    const response = await AccountsService.unban(id);
    if (response.ok) {
      getAllAccounts();
    } else {
      return {
        success: false,
        message: "No se ha podido desbloquear el usuario.",
      };
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {accounts.length > 0 ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "App", url: "#" },
                      { title: "Usuarios", url: "/usuarios" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Usuarios registrados"
                      value={accounts.length}
                      icon="fas fa-users"
                      color="bg-success"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-success">
                      <div className="card-header">
                        <h3 className="card-title">
                          Registros de cuentas en la App
                        </h3>
                      </div>
                      <div className="card-body">
                        <table
                          id="tblAccounts"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Email</th>
                              <th>Estado</th>
                              <th>Perfil de Deportista</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {accounts.map((account) => {
                              return (
                                <tr
                                  key={account.id}
                                  className={
                                    account.isBanned ? "bg-warning" : null
                                  }
                                >
                                  <td>{account.id}</td>
                                  <td>{account.email}</td>
                                  <td>
                                    {account.isBanned
                                      ? "Baneado"
                                      : account.active
                                      ? "Activo"
                                      : "Inactivo"}
                                  </td>
                                  <td>
                                    {account.user ? (
                                      <Link to={"/deportistas/" + account.id}>
                                        {" "}
                                        {account.user.name +
                                          " " +
                                          account.user.surname}
                                      </Link>
                                    ) : (
                                      "Sin completar"
                                    )}
                                  </td>
                                  <td>
                                    {account.isBanned ? (
                                      <button
                                        className="btn btn-success" //btn-sm
                                        onClick={() => {
                                          unban(account.id);
                                        }}
                                      >
                                        <i className="fas fa-redo"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger" //btn-sm
                                        onClick={() => {
                                          ban(account.id);
                                        }}
                                      >
                                        <i className="fas fa-ban"></i>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};

export default Accounts;
