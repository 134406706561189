import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/teams", requestOptions);
}

async function _get(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/teams/" + id, requestOptions);
}

async function _getTeamMembers(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/teams/" + id + "/members", requestOptions);
}

async function _createTeam(name, captainId, boxId, categoryId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({ name, captainId, boxId, categoryId }),
  };

  return fetch(ApiUrlBase + "/teams", requestOptions);
}

async function _updateTeam(id, name, captainId, boxId, categoryId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({ name, captainId, boxId, categoryId }),
  };

  return fetch(ApiUrlBase + "/teams/" + id, requestOptions);
}

async function _addTeamUser(teamId, userId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({ id: userId }),
  };

  return fetch(ApiUrlBase + "/teams/" + teamId + "/members", requestOptions);
}

async function _deleteTeamUser(teamId, userId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(
    ApiUrlBase + "/teams/" + teamId + "/members/" + userId,
    requestOptions
  );
}

async function _getTeamsWithoutGroup() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/teams/unassigned", requestOptions);
}

async function _getTeamFixtures(teamid) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/teams/" + teamid + "/fixtures", requestOptions);
}

export const TeamsService = {
  getAll: _getAll,
  get: _get,
  getTeamMembers: _getTeamMembers,
  createTeam: _createTeam,
  updateTeam: _updateTeam,
  addTeamUser: _addTeamUser,
  deleteTeamUser: _deleteTeamUser,
  getTeamsWithoutGroup: _getTeamsWithoutGroup,
  getTeamFixtures: _getTeamFixtures,
};
