import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../helpers/AuthContext";
import { AccountsService } from "../../../../services/accountsService";

const UserSession = () => {
  const [account, setAccount] = useState(null);

  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    //get me from api
    const getUser = async () => {
      const response = await AccountsService.me();
      if (response.ok) {
        const result = await response.json();

        setAccount(result);
      } else {
        return {
          success: false,
          message: "No se ha podido obtener el usuario de base de datos.",
        };
      }
    };

    getUser();
  }, []);

  return (
    <>
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img
            src="/dist/img/user2-160x160.jpg"
            className="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div className="info">
          <a href="#" className="d-block">
            {account && account.user
              ? account.user.name + " " + account.user.surname
              : account
              ? account.email
              : ""}
          </a>
        </div>
      </div>
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="info">
          <a onClick={signOut}>
            <span className="mr-2" style={{ cursor: "pointer" }}>
              Desconectar
            </span>
            <i className="fas fa-sign-out-alt"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default UserSession;
