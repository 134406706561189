import { useEffect, useRef, useState } from "react";
import Title from "../../components/Common/Title/Title";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { FixturesService } from "../../services/fixtureService";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Common/Alert";
import FixtureTeamDetails from "../../components/Common/FixtureTeamDetails";
import MatchDetails from "../../components/Common/MatchDetails";
import moment from "moment";
import Modal from "../../components/Common/Modal";
import EditFixtureResult from "./EditResults/EditFixtureResult";

const Fixture = () => {
  const navigate = useNavigate();

  const { fixtureid } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [fixture, setFixture] = useState(null);
  const [alertType, setAlertType] = useState("info");
  const [alertTitle, setAlertTitle] = useState("info");

  const [fixtureSchedules, setFixtureSchedules] = useState([]);

  const fetchFixture = async () => {
    const response = await FixturesService.getFixtureById(fixtureid);
    if (response.ok) {
      const data = await response.json();
      setFixture(data);
    }
  };

  const fetchFixtureSchedules = async () => {
    const response = await FixturesService.getFixtureSchedule(fixtureid);
    if (response.ok) {
      const data = await response.json();
      setFixtureSchedules(data);
    }
  };

  useEffect(() => {
    Promise.all([fetchFixture(), fetchFixtureSchedules()]).then(() =>
      setIsLoading(false)
    );
  }, []);

  useEffect(() => {
    if (fixture) {
      switch (fixture.status) {
        case "Finished":
          setAlertType("success");
          setAlertTitle("Finalizado");
          break;
        case "Scheduled":
          setAlertType("warning");
          setAlertTitle("Programado");
          break;
        case "Not Scheduled":
          setAlertType("danger");
          setAlertTitle("No programado");
          break;
        case "Postponed":
          setAlertType("danger");
          setAlertTitle("Aplazado");
          break;
      }
    }
  }, [fixture]);

  const [editMode, setEditMode] = useState(false);
  const switchEditRef = useRef(null);
  const dateTextRef = useRef(null);

  const [newDate, setNewDate] = useState(null);
  const [newStatus, setNewStatus] = useState(null);

  const initDateTimePicker = () => {
    window.$("#reservationdatetime").datetimepicker({
      icons: { time: "far fa-clock" },
      locale: "es", // Set locale to Spanish
      format: "DD-MM-YYYY HH:mm", // Set your desired date format
      defaultDate:
        fixture && fixture.date
          ? moment(new Date(fixture.date + "z"))
          : moment(new Date()),
    });
  };

  useEffect(() => {
    if (editMode) {
      initDateTimePicker();
      setNewStatus(fixture.status);
      setNewDate(fixture.date);
    }
  }, [editMode]);

  const [rescheduleFormError, setRescheduleFormError] = useState(null);

  const updateFixture = async () => {
    setRescheduleFormError(null);
    var newUTCDate = moment(dateTextRef.current.value, "DD-MM-YYYY HH:mm");

    if (newStatus === "Not Scheduled" || newStatus === "Postponed") {
      newUTCDate = null;
    }

    // check if the date has value when the status is scheduled or finished
    if (
      (newStatus === "Scheduled" || newStatus === "Finished") &&
      newUTCDate === "Invalid Date"
    ) {
      setRescheduleFormError(
        "Es obligatorio seleccionar una fecha y hora si el estado es programado o finalizado"
      );
      return;
    }

    const response = await FixturesService.rescheduleFixture(
      fixture.id,
      newUTCDate,
      newStatus
    );

    if (response.ok) {
      fetchFixture();
      switchEditRef.current.checked = false;
      setEditMode(false);
    }
  };

  const [showEditScore, setShowEditScore] = useState(false);

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Enfrentamientos", url: "#" },
                      {
                        title: `${fixture.homeTeam.name} vs. ${fixture.awayTeam.name}`,
                        url: "#",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <Alert
                      title={alertTitle}
                      type={alertType}
                      icon="fas fa-exclamation-triangle"
                    />
                  </div>
                </div>

                {/* Seccion de los horarios */}

                <div className="row">
                  <div className="col-md-5">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="font-weight-light">Propuestas</h3>
                      </div>
                      <div className="card-body">
                        {fixtureSchedules.length > 0 ? (
                          fixtureSchedules.map((schedule) => (
                            <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                              <p
                                className={`text-xl ${
                                  schedule.fixtureDateRequestStatus === 1
                                    ? "text-warning"
                                    : schedule.fixtureDateRequestStatus === 2
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                <i className="far fa-clock"></i>
                              </p>
                              <p className="d-flex flex-column text-right">
                                <span className="font-weight-bold">
                                  {moment(
                                    new Date(schedule.requestedDatetime + "z")
                                  ).format("DD/MM/YYYY HH:mm")}
                                </span>
                                <span className="text-muted">
                                  {schedule.fixtureDateRequestStatus === 1
                                    ? "Pendiente"
                                    : schedule.fixtureDateRequestStatus === 2
                                    ? "Aceptado"
                                    : "Rechazado"}
                                </span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <div>Todavía no hay horarios propuestos</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="font-weight-light">Horario</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  value={editMode}
                                  ref={switchEditRef}
                                  onChange={(e) =>
                                    setEditMode(e.target.checked)
                                  }
                                  className="custom-control-input"
                                  id="edit"
                                />
                                <label
                                  className="custom-control-label"
                                  for="edit"
                                >
                                  Activar el modo edición
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {editMode ? (
                          <div>
                            <div className="form-group">
                              <label>Fecha y Hora:</label>
                              <div
                                className="input-group date"
                                id="reservationdatetime"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className={
                                    (rescheduleFormError ? "is-invalid " : "") +
                                    "form-control datetimepicker-input"
                                  }
                                  data-target="#reservationdatetime"
                                  ref={dateTextRef}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#reservationdatetime"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-calendar"></i>
                                  </div>
                                </div>
                                <span className="invalid-feedback">
                                  {rescheduleFormError}
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Estado:</label>
                              <select
                                className="form-control"
                                value={newStatus}
                                onChange={(e) => setNewStatus(e.target.value)}
                              >
                                <option value="Not Scheduled">
                                  No Programado
                                </option>
                                <option value="Scheduled">Programado</option>
                                <option value="Postponed">Aplazado</option>
                                <option value="Finished">Finalizado</option>
                              </select>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => updateFixture()}
                                >
                                  Guardar Cambios
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                              <p className="text-xl text-primary">
                                <i className="far fa-calendar-alt"></i>
                              </p>
                              <p className="d-flex flex-column text-right">
                                <span className="font-weight-bold">
                                  {fixture.date
                                    ? moment(
                                        new Date(fixture.date + "z")
                                      ).format("DD/MM/YYYY HH:mm")
                                    : "Sin Definir"}
                                </span>
                                <span className="text-muted">DÍA Y HORA</span>
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                              <p className="text-xl text-success">
                                <i className="fas fa-unlock-alt"></i>
                              </p>
                              <p className="d-flex flex-column text-right">
                                <span className="font-weight-bold">
                                  {alertTitle}
                                </span>
                                <span className="text-muted">ESTADO</span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Seccion de los dos equipos */}

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="font-weight-light">
                      Resultados{" "}
                      <a
                        href="#"
                        className="text-danger ml-4"
                        onClick={() => setShowEditScore(true)}
                      >
                        <i className="fas fa-edit"></i>
                      </a>
                    </h2>
                  </div>
                </div>

                <Modal
                  body={<EditFixtureResult fixture={fixture} />}
                  show={showEditScore}
                  title={"Editar resultado"}
                  handleClose={() => {
                    setShowEditScore(false);
                    fetchFixture();
                  }}
                />

                <div className="row">
                  <div className="col-md-6">
                    <FixtureTeamDetails fixture={fixture} isHome={true} />
                  </div>
                  <div className="col-md-6">
                    <FixtureTeamDetails fixture={fixture} isHome={false} />
                  </div>
                </div>

                {/* Seccion de los detalles de enfrentamientos */}
                {fixture.matches.map((match) => (
                  <div className="row">
                    <div className="col">
                      <MatchDetails
                        match={match}
                        fixtureId={fixture.id}
                        onUpdateMatch={() => {
                          FixturesService.refreshScore().then(() =>
                            fetchFixture()
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};

export default Fixture;
