import { useParams } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import { useEffect, useState } from "react";
import Footer from "../../components/Layout/Footer";
import Loader from "../../components/Layout/Loader";
import Settings from "../../components/Layout/Settings";
import { GroupService } from "../../services/groupService";
import { CategoriesService } from "../../services/categoriesService";
import { TerritoriesService } from "../../services/territoriesService";
import { DivisionsService } from "../../services/divisionsService";
import { TeamsService } from "../../services/teamsService";
import { MiscService } from "../../services/miscService";
import Title from "../../components/Common/Title/Title";
import Calendar from "../../components/Common/Calendar";
import Table from "../../components/Common/Table";
const GroupView = () => {
  const { groupid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const [group, setGroup] = useState(null);
  const [teams, setTeams] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [standings, setStandings] = useState([]);

  const [name, setTxtName] = useState("");
  const [category, setCategory] = useState("");
  const [territory, setTerritory] = useState("");
  const [division, setDivision] = useState("");

  const [groupTeams, setGroupTeams] = useState([]);
  const [teamsWithoutGroup, setTeamsWithoutGroup] = useState([]);
  const [showTeamsTable, setShowTeamsTable] = useState(false);

  const [categories, setCategories] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  //Calendario
  const [calendar, setCalendar] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getCalendar = async () => {
    const response = await GroupService.getGroupCalendar(groupid);
    const data = await response.json();
    setCalendar(data);
  };

  useEffect(() => {
    const loadFullGroupData = async () => {
      // load group
      const response = await GroupService.get(groupid);
      const data = await response.json();
      setGroup(data);
      setTxtName(data.name);
      setCategory(data.categoryId);
      setTerritory(data.territoryId);
      setDivision(data.divisionId);

      //load fixtures
      const groupFixturesResponse = await GroupService.getGroupCalendar(
        groupid
      );
      const groupFixtures = groupFixturesResponse.json();
      setFixtures(groupFixtures);

      //load standings
      const groupStandingsResponse = await GroupService.getGroupStandings(
        groupid
      );
      const groupStandings = await groupStandingsResponse.json();
      setStandings(groupStandings);

      //load teams
      const groupTeamsResponse = await GroupService.getGroupTeams(groupid);
      const groupTeams = await groupTeamsResponse.json();
      setTeams(groupTeams);
      setIsLoading(false);
    };

    loadFullGroupData();
  }, []);

  const deleteCalendar = async () => {
    const response = await GroupService.deleteCalendar(groupid);
    getCalendar();
    setShowCalendar(false);
  };

  const createCalendar = async () => {
    const response = await GroupService.createCalendar(groupid);
    getCalendar();
    setShowCalendar(true);
  };

  const swapFixture = async (fixtureId) => {
    const response = await GroupService.swapFixture(fixtureId);
    getCalendar();
  };

  useEffect(() => {
    getCalendar();
  }, []);

  const getCategories = async () => {
    const response = await CategoriesService.getAll();
    const data = await response.json();
    setCategories(data);
  };

  const getTerritories = async () => {
    const response = await TerritoriesService.getAll();
    const data = await response.json();
    setTerritories(data);
  };

  const getDivisions = async () => {
    const response = await DivisionsService.getAll();
    const data = await response.json();
    setDivisions(data);
  };

  const getGroupTeams = async () => {
    const response = await GroupService.getGroupTeams(groupid);
    const data = await response.json();
    setGroupTeams(data);
  };

  const getTeamsWithoutGroup = async () => {
    const response = await TeamsService.getTeamsWithoutGroup();
    const data = await response.json();
    const mappedData = data.map((team) => {
      const boxProvince = team.box
        ? provinces.find((p) => p.id === team.box.provinceId)
        : provinces.find((p) => p.id === team.captain.provinceId);
      const boxCity = team.box
        ? cities.find((c) => c.id === team.box.cityId)
        : cities.find((p) => p.id === team.captain.provinceId);

      return {
        ...team,
        province: boxProvince ? boxProvince.name : null,
        city: boxCity ? boxCity.name : null,
      };
    });
    setTeamsWithoutGroup(mappedData);
  };

  const getProvinces = async () => {
    const response = await MiscService.getAllProvinces();
    const data = await response.json();
    setProvinces(data);
  };

  const getCities = async () => {
    const response = await MiscService.getAllCities();
    const data = await response.json();
    setCities(data);
  };

  const addGroupTeam = async (team) => {
    const response = await GroupService.addGroupTeam(groupid, team.id);
    const data = await response.json();
    setGroupTeams(data);
    getTeamsWithoutGroup().then(() => {
      setShowTeamsTable(false);
    });
  };

  const deleteGroupTeam = async (team) => {
    const response = await GroupService.deleteGroupTeam(groupid, team.id);
    const data = await response.json();
    setGroupTeams(data);
    getTeamsWithoutGroup();
  };

  useEffect(() => {
    // call all functions and wait to complete all
    Promise.all([
      getCategories(),
      getTerritories(),
      getDivisions(),
      getGroupTeams(),
      getProvinces(),
      getCities(),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (provinces.length > 0 && cities.length > 0) {
      getTeamsWithoutGroup();
    }
  }, [provinces, cities]);

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Grupos", url: "/grupos" },
                      { title: group.name, url: "#" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-primary">
                          <div className="card-header">
                            <h3 className="card-title">Datos del grupo</h3>
                          </div>
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor="name">Nombre</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Nombre"
                                value={group.name}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="categoyid">Categoría</label>
                              <select
                                className={
                                  (errors.category ? "is-invalid " : "") +
                                  "form-control"
                                }
                                id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                disabled
                              >
                                <option value="">
                                  -- Selecciona una categoría --
                                </option>
                                {categories.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                              </select>
                              <span className="invalid-feedback">
                                {errors.category}
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="territoryid">Territorio</label>
                              <select
                                className={
                                  (errors.territory ? "is-invalid " : "") +
                                  "form-control"
                                }
                                id="territory"
                                value={territory}
                                onChange={(e) => setTerritory(e.target.value)}
                                disabled
                              >
                                <option value="">
                                  -- Selecciona un territorio --
                                </option>
                                {territories.map((territory) => (
                                  <option
                                    key={territory.id}
                                    value={territory.id}
                                  >
                                    {territory.name}
                                  </option>
                                ))}
                              </select>
                              <span className="invalid-feedback">
                                {errors.territory}
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="divisionid">División</label>
                              <select
                                className={
                                  (errors.division ? "is-invalid " : "") +
                                  "form-control"
                                }
                                id="division"
                                value={division}
                                onChange={(e) => setDivision(e.target.value)}
                                disabled
                              >
                                <option value="">
                                  -- Selecciona una división --
                                </option>
                                {divisions.map((division) => (
                                  <option key={division.id} value={division.id}>
                                    {division.name}
                                  </option>
                                ))}
                              </select>
                              <span className="invalid-feedback">
                                {errors.division}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-primary">
                      <div className="card-header">
                        <>
                          <h3 className="card-title">Equipos</h3>
                          {showTeamsTable ? (
                            <button
                              type="button"
                              class="btn btn-primary btn-sm float-right"
                              onClick={() => {
                                setShowTeamsTable(false);
                              }}
                            >
                              <i className="fas fa-undo"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn-primary btn-sm float-right"
                              onClick={() => setShowTeamsTable(true)}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          )}
                        </>
                      </div>
                      <div className="card-body">
                        {showTeamsTable ? (
                          <>
                            <div style={{ textAlign: "center" }}>
                              <h4>Añadir Equipo</h4>
                            </div>
                            <Table
                              data={teamsWithoutGroup}
                              fields={[
                                { objectField: "name", titleField: "Nombre" },
                                {
                                  objectField: "category.name",
                                  titleField: "Categoría",
                                },
                                {
                                  objectField: "province",
                                  titleField: "Provincia",
                                },
                                { objectField: "city", titleField: "Ciudad" },
                              ]}
                              actions={[
                                {
                                  title: "Seleccionar",
                                  ico: "fas fa-solid fa-check",
                                  class: "success",

                                  action: (obj) => {
                                    addGroupTeam(obj);
                                  },
                                },
                              ]}
                            />
                          </>
                        ) : (
                          <>
                            {groupTeams.length > 0 ? (
                              groupTeams.map((team) => (
                                <>
                                  <p>
                                    {team.name}
                                    <button
                                      type="button"
                                      class="btn btn-danger btn-sm float-right"
                                      onClick={() => deleteGroupTeam(team)}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  </p>
                                </>
                              ))
                            ) : (
                              <h2> No hay equipos asignados </h2>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">Calendario</h3>
                      </div>
                      <div className="card-body">
                        {calendar.length > 0 ? (
                          <>
                            {calendar.map((vs) => (
                              <>
                                <Calendar
                                  week={vs.week}
                                  date={vs.date}
                                  homeTeamName={vs.homeTeam.name}
                                  awayTeamName={vs.awayTeam.name}
                                />
                                <div style={{ textAlign: "center" }}>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => swapFixture(vs.id)}
                                  >
                                    Intercambiar Equipos
                                  </button>
                                </div>
                                <hr />
                              </>
                            ))}
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => deleteCalendar()}
                            >
                              Resetear Calendario
                            </button>
                          </>
                        ) : (
                          <>
                            {groupTeams.length > 1 ? (
                              <>
                                <h4>Todavía no hay calendario creado</h4>
                                <br />
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() => createCalendar()}
                                >
                                  Generar Calendario
                                </button>
                              </>
                            ) : (
                              <h4>
                                Hay que tener más de 2 equipos asignados para
                                crear el calendario
                              </h4>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">Clasificación</h3>
                      </div>
                      <div className="card-body">
                        <h4>Clasificación en proceso</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};
export default GroupView;
