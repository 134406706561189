// export const UrlBase = "https://dev.ncleague.es";
// export const ApiUrlBase = "https://dev.ncleague.es";
export const UrlBase = "https://api.ncleague.es";
export const ApiUrlBase = "https://api.ncleague.es";
// export const UrlBase = "https://localhost:7290";
// export const ApiUrlBase = "https://localhost:7290";

export const PaymentStatus = {
  PENDING: 1,
  PAID: 2,
  FAILED: 3,
};

export const PaymentStatusDescription = (id) => {
  switch (id) {
    case PaymentStatus.PENDING:
      return "Pendiente de procesar";
    case PaymentStatus.PAID:
      return "Pago aceptado";
    case PaymentStatus.FAILED:
      return "Pago rechazado";
    default:
      return "";
  }
};

export const NotificationReceiverType = {
  TEAM_MEMBER: 1,
  TEAM_CAPTAIN: 2,
};
