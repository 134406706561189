import { ApiUrlBase } from "../helpers/constants";

async function _getAllProvinces() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/provinces", requestOptions);
}

async function _getAllCities() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/cities", requestOptions);
}

async function _getCitiesByProvinceId(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/provinces/" + id + "/cities", requestOptions);
}

export const MiscService = {
  getAllProvinces: _getAllProvinces,
  getAllCities: _getAllCities,
  getCitiesByProvinceId: _getCitiesByProvinceId,
};
