import { useParams } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import { useEffect, useState } from "react";
import Footer from "../../components/Layout/Footer";
import Loader from "../../components/Layout/Loader";
import Settings from "../../components/Layout/Settings";
import { GroupService } from "../../services/groupService";
import { CategoriesService } from "../../services/categoriesService";
import { TeamsService } from "../../services/teamsService";
import { UsersService } from "../../services/usersService";
import Title from "../../components/Common/Title/Title";
import { BoxesService } from "../../services/boxesService";
import Table from "../../components/Common/Table";
import TeamCalendar from "../../components/Common/TeamCalendar";
import FixtureItem from "../../components/Common/FixtureItem/FixtureItem";
import { UrlBase } from "../../helpers/constants";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Common/Modal/Modal";
import { ActionsService } from "../../services/actionsService";
import Timeline from "../../components/Common/Timeline";
const TeamView = () => {
  const navigate = useNavigate();

  const { teamid } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [team, setTeam] = useState([null]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamActions, setTeamActions] = useState([]);

  const [usersWithoutTeam, setUsersWithoutTeam] = useState([]);
  const [showUsersTable, setShowUsersTable] = useState(false);

  const [calendar, setCalendar] = useState([]);

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  useEffect(() => {
    const loadFullTeamData = async () => {
      // load team
      const response = await TeamsService.get(teamid);
      const data = await response.json();
      setTeam(data);

      //load members
      getTeamMembers();
      const teamFixturesResponse = await TeamsService.getTeamFixtures(teamid);
      const teamFixtures = await teamFixturesResponse.json();
      setCalendar(teamFixtures);
      setIsLoading(false);

      //load actions
      const actionsResponse = await ActionsService.getByTeam(teamid);
      const actions = await actionsResponse.json();
      console.log(actions);
      setTeamActions(actions);
    };

    loadFullTeamData();
  }, []);

  const updateCaptainId = async (newCaptainId) => {
    const response = await TeamsService.updateTeam(
      teamid,
      team.name,
      newCaptainId,
      team.box.id,
      team.categoryId
    );
    // update full team data
    const teamResponse = await TeamsService.get(teamid);
    const data = await teamResponse.json();
    setTeam(data);
  };
  const getTeamMembers = async () => {
    const response = await TeamsService.getTeamMembers(teamid);
    const data = await response.json();
    setTeamMembers(data);
  };

  const getUsersWithoutTeam = async () => {
    const response = await UsersService.getUsersWithoutTeam();
    const data = await response.json();
    setUsersWithoutTeam(data);
  };

  const addTeamUser = async (user) => {
    const response = await TeamsService.addTeamUser(teamid, user.id);
    const data = await response.json();
    setTeamMembers(data);
    getUsersWithoutTeam().then(() => {
      setShowUsersTable(false);
    });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);

  const showDeleteModalAction = (user) => {
    setDeleteUser(user);
    setShowDeleteModal(true);
  };

  const deleteTeamUser = async (user) => {
    const response = await TeamsService.deleteTeamUser(teamid, user.id);
    const data = await response.json();
    setTeamMembers(data);
    getUsersWithoutTeam();
  };

  useEffect(() => {
    // call all functions and wait to complete all
    Promise.all([getTeamMembers(), getUsersWithoutTeam()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Equipos", url: "/equipos" },
                      { title: team.name, url: "#" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  {!showUsersTable && (
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header border-0">
                          <h3 className="card-title">TEAM OVERVIEW</h3>
                        </div>
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                            <p className="text-success text-xl">
                              <i className="fas fa-signature"></i>
                            </p>
                            <p className="d-flex flex-column text-right">
                              <span className="font-weight-bold">
                                {team.name}
                              </span>
                              <span className="text-muted">NOMBRE</span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                            <p className="text-warning text-xl">
                              <i className="fas fa-users"></i>
                            </p>
                            <p className="d-flex flex-column text-right">
                              <span className="font-weight-bold">
                                {teamMembers.length}
                              </span>
                              <span className="text-muted">ATLETAS</span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                            <p className="text-danger text-xl">
                              <i className="fas fa-user-shield"></i>
                            </p>
                            <p className="d-flex flex-column text-right">
                              <span className="font-weight-bold">
                                {team.captain.name} {team.captain.surname}
                              </span>
                              <span className="text-muted">CAPITÁN</span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between align-items-center mb-0">
                            <p className="text-primary text-xl">
                              <i className="fas fa-home"></i>
                            </p>
                            <p className="d-flex flex-column text-right">
                              <span className="font-weight-bold">
                                {team.box
                                  ? team.box.name
                                  : "Equipo independiente"}
                              </span>
                              <span className="text-muted">BOX</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">ATLETAS</h3>
                        <div className="card-tools">
                          {showUsersTable ? (
                            <button
                              type="button"
                              className="btn btn-tool"
                              onClick={() => {
                                setShowUsersTable(false);
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-tool"
                              onClick={() => {
                                setShowUsersTable(true);
                              }}
                            >
                              <i className="fas fa-user-plus"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        {showUsersTable && (
                          <div className="row">
                            <div className="col">
                              <h3 className="font-weight-light">
                                Añadir atleta al team
                              </h3>
                              <hr />
                              <Table
                                data={usersWithoutTeam}
                                fields={[
                                  {
                                    objectField: "personalId",
                                    titleField: "NIF/NIE",
                                  },
                                  {
                                    objectField: "name",
                                    titleField: "Nombre",
                                  },
                                  {
                                    objectField: "surname",
                                    titleField: "Apellidos",
                                  },
                                ]}
                                actions={[
                                  {
                                    title: "Añardir Usuario",
                                    ico: "fas fa-plus",
                                    class: "primary",
                                    action: (obj) => {
                                      addTeamUser(obj);
                                      setShowUsersTable(false);
                                    },
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        )}
                        {!showUsersTable && teamMembers.length > 0 && (
                          <div className="row">
                            <div className="col">
                              <div class="form-group">
                                <label>Elegir capitán</label>
                                <select
                                  class="form-control"
                                  value={team.captainId}
                                  onChange={(e) => {
                                    updateCaptainId(e.target.value);
                                  }}
                                >
                                  {teamMembers.map((user) => (
                                    <option value={user.id}>
                                      {user.name} {user.surname}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <ul className="users-list clearfix">
                                {teamMembers.map((user) => (
                                  <li style={{ width: "20%" }}>
                                    <img
                                      src={
                                        user.profilePic
                                          ? `${UrlBase}/profile-pics/${user.profilePic}`
                                          : "/dist/img/user0-128x128.png"
                                      }
                                      //src={"/dist/img/user0-128x128.png"}
                                      alt="User Image"
                                    />
                                    <a
                                      className="users-list-name"
                                      href="#"
                                      onClick={() => {
                                        navigate("/deportistas/" + user.id);
                                      }}
                                    >
                                      {user.name} {user.surname}
                                    </a>
                                    <span className="users-list-date">
                                      <a
                                        href="#"
                                        className="text-danger"
                                        onClick={() => {
                                          showDeleteModalAction(user);
                                        }}
                                      >
                                        <i className="fa fa-user-times"></i>
                                      </a>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">CALENDARIO DEL EQUIPO</h3>
                      </div>
                      <div className="card-body">
                        {calendar.length > 0 ? (
                          <>
                            {calendar.map((vs) => (
                              <FixtureItem fixture={vs} showWeek={true} />
                            ))}
                          </>
                        ) : (
                          <>
                            <h4>Todavía no hay calendario creado</h4>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">TIMELINE</h3>
                      </div>
                      <div className="card-body">
                        <Timeline events={teamActions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Modal
            title={"Eliminar usuario del equipo"}
            body={
              <h4 className="font-weight-light">
                ¿Estás seguro de que quieres eliminar a {deleteUser?.name}{" "}
                {deleteUser?.surname} del equipo?
              </h4>
            }
            show={showDeleteModal}
            handleClose={() => {
              setShowDeleteModal(false);
            }}
            acceptButton={{
              text: "Eliminar",
              action: () => {
                deleteTeamUser(deleteUser);
                setShowDeleteModal(false);
              },
            }}
            cancelButton={{
              text: "Cancelar",
            }}
          />

          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};
export default TeamView;
