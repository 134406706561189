const Calendar = ({ week, date, homeTeamName, awayTeamName }) => {
  const fecha = new Date(date);
  const fechaFormateada =
    date !== null && date !== undefined && date !== ""
      ? fecha.toLocaleDateString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "No definida";
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left" }}>
          <div>
            <strong>Semana:</strong> {week}
          </div>
          <div>
            <strong>Fecha:</strong> {fechaFormateada}
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <div>
            <strong>Equipo Local:</strong> {homeTeamName}
          </div>
          <div>
            <strong>Equipo Visitante:</strong> {awayTeamName}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
