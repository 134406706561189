import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SimpleItem = ({ name, link, faIcon, badge, badgeClass, active }) => {
  const [isActive, setIsActive] = useState(active);

  const location = useLocation();

  useEffect(() => {
    setIsActive(location.pathname == link);
  }, [location]);

  return (
    <li className="nav-item">
      <Link
        to={link == null ? "#" : link}
        className={"nav-link " + (isActive != null && isActive ? "active" : "")}
      >
        <i
          className={(faIcon == null ? "far fa-circle" : faIcon) + " nav-icon"}
        ></i>
        <p>
          {name == null ? "Menu Item" : name}

          {badge != null && badgeClass != null ? (
            <span className={"right badge badge-" + badgeClass}>{badge}</span>
          ) : (
            <></>
          )}
        </p>
      </Link>
    </li>
  );
};

export default SimpleItem;
