import { useEffect, useRef, useState } from "react";
import { BoxesService } from "../../../services/boxesService";
import { CategoriesService } from "../../../services/categoriesService";
import { TeamsService } from "../../../services/teamsService";
import { UsersService } from "../../../services/usersService";
import Table from "../../../components/Common/Table";

const TeamEdit = ({ team, newTeam }) => {
  const [teamId, setTeamId] = useState(newTeam ? undefined : team?.id);

  // create state variables for each input: Name(textbox), Captain(dropdown), Box(dropdown) and Category(dropdown)
  const [name, setName] = useState(team?.name);
  const [captain, setCaptain] = useState(team?.captainId);
  const [box, setBox] = useState(team?.boxId);
  const [category, setCategory] = useState(team?.categoryId);

  //create state variable for team members, categories and boxes
  const [teamMembers, setTeamMembers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [users, setUsers] = useState([]);

  // create state variable for form state
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(newTeam);
  const [isNewTeam, setIsNewTeam] = useState(newTeam);
  const [showUsersTable, setShowUsersTable] = useState(false);
  const switchEditRef = useRef(null);

  // handle effects to get all boxes, categories and team members
  useEffect(() => {
    const getBoxes = async () => {
      const response = await BoxesService.getAll();
      const data = await response.json();

      // order boxes by province name and then by name
      data.sort((a, b) => {
        if (a.province.name > b.province.name) {
          return 1;
        }
        if (a.province.name < b.province.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      setBoxes(data);
    };

    const getCategories = async () => {
      const response = await CategoriesService.getAll();
      const data = await response.json();
      setCategories(data);
    };

    const getTeamMembers = async () => {
      const response = await TeamsService.getTeamMembers(team.id);
      const data = await response.json();
      setTeamMembers(data);
    };

    const getUsersWithoutTeam = async () => {
      const response = await UsersService.getUsersWithoutTeam();
      const data = await response.json();
      setUsers(data);
    };

    // call all functions and wait to complete all
    Promise.all([
      getBoxes(),
      getCategories(),
      getTeamMembers(),
      getUsersWithoutTeam(),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  const addTeamUser = async (user) => {
    const response = await TeamsService.addTeamUser(teamId, user.id);
    const data = await response.json();
    setTeamMembers(data);
    setShowUsersTable(false);
  };

  const deleteTeamUser = async (user) => {
    const response = await TeamsService.deleteTeamUser(teamId, user.id);
    const data = await response.json();
    setTeamMembers(data);
  };

  //validate name. is required, at least 3 characters, no more tan 50 characters
  const validateName = () => {
    if (name.length < 3) {
      return "El nombre debe tener al menos 3 caracteres";
    }
    if (name.length > 50) {
      return "El nombre no debe tener más de 50 caracteres";
    }
    return null;
  };

  // validate captain. is required
  const validateCaptain = () => {
    if (captain === "") {
      return "Debe seleccionar un capitán";
    }
    return null;
  };

  // validate category. is required
  const validateCategory = () => {
    if (category === "") {
      return "Debe seleccionar una categoría";
    }
    return null;
  };

  // validate all
  // validate all
  const validateAll = () => {
    const formErrors = {
      name: validateName(),
      category: validateCategory(),
      captain: validateCaptain(),
    };

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    console.log(errors);
    if (Object.values(errors).every((x) => x === null)) {
      if (isNewTeam) {
        const createTeam = async () => {
          const response = await TeamsService.createTeam(
            name,
            captain,
            box === "" ? null : box,
            category
          );

          if (response.ok) {
            setIsNewTeam(false);
            setEdit(false);
            const newTeam = await response.json();
            setTeamId(newTeam.id);
            // show success message
          } else {
            // show error message
          }
        };

        createTeam();
      } else {
        const updateTeam = async () => {
          const response = await TeamsService.updateTeam(
            teamId,
            name,
            captain,
            box === "" ? null : box,
            category
          );

          if (response.ok) {
            switchEditRef.current.checked = false;
            setEdit(false);
            // show success message
          } else {
            // show error message
          }
        };

        updateTeam();
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (showUsersTable) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Añadir Usuario a equipo</h3>
                </div>
                <div className="card-body">
                  <Table
                    data={users}
                    fields={[
                      { objectField: "name", titleField: "Nombre" },
                      {
                        objectField: "surname",
                        titleField: "Apellidos",
                      },
                      {
                        objectField: "personalId",
                        titleField: "DNI",
                      },
                    ]}
                    actions={[
                      {
                        title: "Seleccionar",
                        ico: "fas fa-solid fa-check",
                        class: "success",

                        action: (obj) => {
                          addTeamUser(obj);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                value={edit}
                ref={switchEditRef}
                onChange={(e) => setEdit(e.target.checked)}
                class="custom-control-input"
                id="edit"
              />
              <label class="custom-control-label" for="edit">
                Activar el modo edición
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="name">
                    Nombre <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.name ? "is-invalid " : "") + "form-control"
                    }
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.name}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="provinceId">
                    Capitán <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.captain ? "is-invalid " : "") + "form-control"
                    }
                    id="captain"
                    value={captain}
                    onChange={(e) => setCaptain(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="">
                      -- Selecciona un miembro del equipo --
                    </option>
                    {teamMembers.map((member) => (
                      <option key={member.id} value={member.id}>
                        {member.name + " " + member.surname}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.captain}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="provinceId">
                    Categoría <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.category ? "is-invalid " : "") + "form-control"
                    }
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="">-- Selecciona una categoría --</option>
                    {categories.map((categoryType) => (
                      <option key={categoryType.id} value={categoryType.id}>
                        {categoryType.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.category}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="provinceId">
                    Box <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.box ? "is-invalid " : "") + "form-control"
                    }
                    id="box"
                    value={box}
                    onChange={(e) => setBox(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="">-- Sin box asociado --</option>
                    {boxes.map((nclBox) => (
                      <option key={nclBox.id} value={nclBox.id}>
                        {nclBox.province.name.toUpperCase() +
                          " - " +
                          nclBox.name +
                          " (" +
                          nclBox.city.name +
                          ")"}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.box}</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card card-info">
                  <div class="card-header">
                    <span>Miembros</span>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm float-right"
                      onClick={() => setShowUsersTable(true)}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>

                  <div class="card-body">
                    {teamMembers.map((member) => (
                      <>
                        <p>
                          {member.name} {member.surname}
                          <button
                            type="button"
                            class="btn btn-danger btn-sm float-right"
                            onClick={() => deleteTeamUser(member)}
                          >
                            <i className="fas fa-minus"></i>
                          </button>
                        </p>
                      </>
                    ))}
                  </div>
                  {edit ? null : <div class="overlay"></div>}
                </div>
              </div>
            </div>

            {edit ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default TeamEdit;
