import { ApiUrlBase } from "../helpers/constants";

async function _getAllTemplates() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/mail/templates", requestOptions);
}

async function _sendEmail(
  subject,
  html,
  receiverType,
  receiverIds,
  isTest,
  testEmail
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      subject: subject,
      html: html,
      receiverType: receiverType,
      receiverIds: receiverIds,
      isTest: isTest,
      testEmail: testEmail,
    }),
  };

  return fetch(ApiUrlBase + "/mail", requestOptions);
}

export const ComunicationService = {
  getAllTemplates: _getAllTemplates,
  sendEmail: _sendEmail,
};
