import { useState } from "react";
import { FixturesService } from "../../../services/fixtureService";

const EditFixtureResult = ({ fixture }) => {
  const [homeScore, setHomeScore] = useState(fixture.homeScore);
  const [awayScore, setAwayScore] = useState(fixture.awayScore);

  const [errors, setErrors] = useState({ homeScore: null, awayScore: null });

  const [showSaveButton, setShowSaveButton] = useState(false);

  const submitResult = async () => {
    // check errors
    const homeScoreIntValue = parseInt(homeScore);
    const awayScoreIntValue = parseInt(awayScore);

    const validationErrors = {
      homeScore:
        isNaN(homeScoreIntValue) || homeScoreIntValue < 0
          ? "Debe ser un número mayor o igual que 0"
          : null,
      awayScore:
        isNaN(awayScoreIntValue) || awayScoreIntValue < 0
          ? "Debe ser un número mayor o igual que 0"
          : null,
    };
    setErrors(validationErrors);
    for (const key in validationErrors) {
      if (
        validationErrors.hasOwnProperty(key) &&
        validationErrors[key] !== null
      ) {
        return false;
      }
    }

    // TODO: Submit scores
    const response = await FixturesService.setFixtureResult(
      fixture.id,
      homeScore,
      awayScore
    );

    if (response.ok) {
      setShowSaveButton(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h3 className="font-weight-light">{fixture.homeTeam.name}</h3>
        </div>
        <div className="col-md-6">
          <h3 className="font-weight-light">{fixture.awayTeam.name}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="homeScore">
              Puntos <span class="text-danger">*</span>
            </label>
            <input
              type="number"
              className={
                (errors.homeScore ? "is-invalid " : "") + "form-control"
              }
              id="homeScore"
              value={homeScore}
              onChange={(e) => {
                setHomeScore(e.target.value);
                setShowSaveButton(true);
              }}
            />
            <span className="invalid-feedback">{errors.homeScore}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="awayScore">
              Puntos <span class="text-danger">*</span>
            </label>
            <input
              type="number"
              className={
                (errors.awayScore ? "is-invalid " : "") + "form-control"
              }
              id="awayScore"
              value={awayScore}
              onChange={(e) => {
                setAwayScore(e.target.value);
                setShowSaveButton(true);
              }}
            />
            <span className="invalid-feedback">{errors.awayScore}</span>
          </div>
        </div>
      </div>
      {showSaveButton && (
        <div className="row">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={submitResult}
            >
              Guardar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditFixtureResult;
