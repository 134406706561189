import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { FAQsService } from "../../services/FAQsService";
import FAQsEdit from "./FAQsEdit";

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);

  const [showFAQModal, setShowFAQModal] = useState(false);
  const [modalFaqs, setModalFaqs] = useState(null);
  const [isNewFaq, setIsNewFaq] = useState(false);
  const [titleModal, setTitleModal] = useState("Editar FaQ");

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllFAQs = async () => {
    const response = await FAQsService.getAll();
    if (response.ok) {
      const result = await response.json();
      setFaqs(result);
    } else {
      return {
        success: false,
        message: "No se han podido obtener los FAQs de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllFAQs();
  }, []);

  useEffect(() => {
    if (modalFaqs) {
      setShowFAQModal(true);
    } else {
      setShowFAQModal(false);
    }
  }, [modalFaqs]);

  useEffect(() => {
    if (faqs.length > 0) initDataTables();
  }, [faqs]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblFaqs")) {
      window.$("#tblFaqs").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4],
            className: "text-center",
          },
        ],
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {faqs.length > 0 ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Web", url: "#" },
                      { title: "FAQs", url: "/faqs" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="FaQs"
                      value={faqs.length}
                      icon="fas fa-question mr-1"
                    />
                  </div>
                  <div className="col-8"></div>
                  <div className="col-1 text-right">
                    <button
                      class="btn btn-app bg-info mt-5"
                      onClick={() => {
                        setModalFaqs({});
                        setIsNewFaq(true);
                        setTitleModal("Nuevo FaQ");
                      }}
                    >
                      <i class="fas fa-plus"></i> Agregar
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Lista de FAQs</h3>
                      </div>
                      <div className="card-body">
                        <table
                          id="tblFaqs"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Pregunta</th>
                              <th>Respuesta</th>
                              <th>Categoría</th>
                              <th>Tags</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {faqs.map((faq) => {
                              return (
                                <tr>
                                  <td>{faq.question}</td>
                                  <td>{faq.answer}</td>
                                  <td>{faq.category}</td>
                                  <td>{faq.keyWords}</td>
                                  <td>
                                    <button
                                      className="btn btn-success" //btn-sm
                                      title="ver / editar"
                                      onClick={() => {
                                        setModalFaqs(faq);
                                        setIsNewFaq(false);
                                        setTitleModal("Editar FaQ");
                                      }}
                                    >
                                      <i className="fas fa-eye"></i>
                                    </button>{" "}
                                    &nbsp;
                                    <button
                                      className="btn btn-danger" //btn-sm
                                      title="borrar"
                                      onClick={() => {
                                        setModalFaqs(faq);
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
      <Modal
        show={showFAQModal}
        handleClose={() => {
          setModalFaqs(null);
          setShowFAQModal(false);
          getAllFAQs();
        }}
        body={modalFaqs ? <FAQsEdit faq={modalFaqs} newFaq={isNewFaq} /> : null}
        title={titleModal}
      />
    </>
  );
};

export default FAQs;
