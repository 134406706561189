import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { SubscriptionRequestService } from "../../services/subscriptionRequestService";
import SubscriptionRequestEdit from "./SubscriptionRequestEdit";
import { PaymentStatusDescription } from "../../helpers/constants";

const SubscriptionRequest = () => {
  const [subscriptionRequests, setSubscriptionRequests] = useState([]);

  const [showSubscriptionRequestModal, setShowSubscriptionRequestModal] =
    useState(false);
  const [modalSubscriptionRequest, setModalSubscriptionRequest] =
    useState(null);

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllSubscriptionRequest = async () => {
    const response = await SubscriptionRequestService.getAll();
    if (response.ok) {
      const result = await response.json();
      setSubscriptionRequests(result);
    } else {
      return {
        success: false,
        message: "No se han podido obtener las solicitudes de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllSubscriptionRequest();
  }, []);

  useEffect(() => {
    if (modalSubscriptionRequest) setShowSubscriptionRequestModal(true);
    else setShowSubscriptionRequestModal(false);
  }, [modalSubscriptionRequest]);

  useEffect(() => {
    if (subscriptionRequests.length > 0) initDataTables();
  }, [subscriptionRequests]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblSubscriptionRequest")) {
      window.$("#tblSubscriptionRequest").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4],
            className: "text-center",
          },
        ],
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {subscriptionRequests.length > 0 ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Web", url: "#" },
                      {
                        title: "Solicitudes de Inscripción",
                        url: "/subscriptionRequest",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Solicitudes Nuevas"
                      value={subscriptionRequests.length}
                      icon="fas fa-user-plus mr-1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Registros de Solicitudes</h3>
                      </div>
                      <div className="card-body">
                        <table
                          id="tblSubscriptionRequest"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Nombre del Equipo</th>
                              <th>Nombre del Capitán</th>
                              <th>Email</th>
                              <th>Estado del pago</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subscriptionRequests.map((subscriptionRequest) => {
                              return (
                                <tr>
                                  <td>{subscriptionRequest.teamName}</td>
                                  <td>{subscriptionRequest.name}</td>
                                  <td>{subscriptionRequest.email}</td>
                                  <td>
                                    {PaymentStatusDescription(
                                      subscriptionRequest.paymentStatus
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-success" //btn-sm
                                      onClick={() => {
                                        setModalSubscriptionRequest(
                                          subscriptionRequest
                                        );
                                      }}
                                    >
                                      <i className="fas fa-eye"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
      <Modal
        show={showSubscriptionRequestModal}
        handleClose={() => {
          setModalSubscriptionRequest(null);
          setShowSubscriptionRequestModal(false);
        }}
        body={
          modalSubscriptionRequest ? (
            <SubscriptionRequestEdit
              subscriptionRequest={modalSubscriptionRequest}
            />
          ) : null
        }
        title={"Datos del equipo " + modalSubscriptionRequest?.teamName}
      />
    </>
  );
};

export default SubscriptionRequest;
