import logo from "../../../logo.png";

const Loader = () => {
  return (
    <div style={{ paddingTop: "30vh", paddingBottom: "30hv" }}>
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={logo}
          alt="loading"
          className="animation__shake"
          height={60}
          width={60}
        />

        <h5 className="ml-3"> Recuperando datos...</h5>
      </div>
    </div>
  );
};

export default Loader;
