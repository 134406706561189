import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Layout/Loader";
import Alert from "../../../components/Common/Alert";
import { FAQsService } from "../../../services/FAQsService";

const FAQsEdit = ({ faq, newFaq }) => {

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(newFaq);
  const [isNewFaq, setIsNewFaq] = useState(newFaq);
  const switchEditRef = useRef(null);


  const [faqId, setFaqId] = useState(newFaq ? undefined : faq.id);
  const [txtQuestion, setTxtQuestion] = useState(newFaq ? "" : faq.question);
  const [txtAnswer, setTxtAnswer] = useState(newFaq ? "" : faq.answer);
  const [category, setCategory] = useState(newFaq ? "" : faq.category);
  const [keyWords, setKeyWords] = useState(newFaq ? "" : faq.keyWords);


  const validateQuestion = () => {
    if(txtQuestion != undefined){
      if (txtQuestion.length === 0) {
        return "La pregunta es obligatoria.";
      } else if (txtQuestion.length < 10) {
        return "La pregunta debe tener al menos 10 caracteres.";
      } else if (txtQuestion.length > 512) {
        return "La pregunta debe tener menos de 512 caracteres.";
      } else {
        return null;
      }
    }
    else  
      return "La pregunta es obligatoria.";
  };

  const validateAnswer = () => {
    if(txtQuestion != undefined){
      if (txtAnswer.length === 0) {
        return "La respuesta es obligatoria.";
      } else if (txtAnswer.length < 20) {
        return "La respuesta debe tener al menos 10 caracteres.";
      } else if (txtAnswer.length > 4096) {
        return "La respuesta debe tener menos de 4096 caracteres.";
      } else {
        return null;
      }
    }
    else
      return "La respuesta es obligatoria.";
  };

  const validateCategory = () => {
    if(txtQuestion != undefined){
      if (category.length === 0) {
        return "La categoría es obligatoria.";
      } else if (category.length < 2) {
        return "La categoría debe tener al menos 10 caracteres.";
      } else if (category.length > 512) {
        return "La categoría debe tener menos de 512 caracteres.";
      } else {
        return null;
      }
    }
    else  
      return "La categoría es obligatoria.";
  };

  const validateKeyWords = () => {
    if(txtQuestion != undefined){
      if (keyWords.length === 0) {
        return "Las palabras clave son obligatorias.";
      } else if (keyWords.length < 2) {
        return "Las palabras clave deben tener al menos 2 caracteres.";
      } else if (keyWords.length > 255) {
        return "Las palabras clave deben tener menos de 255 caracteres.";
      } else {
        return null;
      }
    }
    else
      return "Las palabras clave es obligatorias.";
  };

  const validateAll = () => {
    const formErrors = {
      question: validateQuestion(),
      answer: validateAnswer(),
      category: validateCategory(),
      keyWords: validateKeyWords(),
    };

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    if (Object.values(errors).every((x) => x === null)) {
      
      if(isNewFaq){
        
        const CreateFAQ = async () => {
          const response = await FAQsService.createFaq(
            txtQuestion,
            txtAnswer,
            category,
            keyWords
          );
  
          if (response.ok) {
            const createdFaq = await response.json();
            setFaqId(createdFaq.id);
            setEdit(false);
            setIsNewFaq(false);
          } else {
            // show error message
          }
        };
        CreateFAQ();
      }
      else{
        const updateFAQ = async () => {
          const response = await FAQsService.updateFaq(
            faqId,
            txtQuestion,
            txtAnswer,
            category,
            keyWords
          );
  
          if (response.ok) {
            switchEditRef.current.checked = false;
            setEdit(false);
            // show success message
          } else {
            // show error message
          }
        };
        updateFAQ();
      }
    }else{
      console.log(errors);
    }
    
  };
  return (
    <div className="row">
      <div className="col-md-12">
      <div class="form-group">
        {isNewFaq ? null:
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            value={edit}
            ref={switchEditRef}
            onChange={(e) => setEdit(e.target.checked)}
            class="custom-control-input"
            id="edit"
          />
          <label class="custom-control-label" for="edit">
            Activar el modo edición
          </label>
        </div>}
        </div>
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">FAQ</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="question">Pregunta</label>
                    <input
                      type="text"
                      className={
                        (errors.question ? "is-invalid " : "") + "form-control"
                      }
                      id="question"
                      value={txtQuestion}
                      onChange={(e) => setTxtQuestion(e.target.value)}
                      disabled={!edit}
                    />
                    <span className="invalid-feedback">{errors.question}</span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="answer">Respuesta</label>
                    <textarea
                      className={
                        (errors.answer ? "is-invalid " : "") + "form-control"
                      }
                      rows={10}
                      id="answer"
                      value={txtAnswer}
                      onChange={(e) => setTxtAnswer(e.target.value)}
                      disabled={!edit}
                    >
                    </textarea>
                    <span className="invalid-feedback">{errors.answer}</span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="category">Categoría</label>
                    <input
                      type="text"
                      className={
                        (errors.category ? "is-invalid " : "") + "form-control"
                      }
                      id="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      disabled={!edit}
                    />
                    <span className="invalid-feedback">{errors.category}</span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="keywords">Tags</label>
                    <input
                      type="text"
                      className={
                        (errors.keyWords ? "is-invalid " : "") + "form-control"
                      }
                      id="keywords"
                      value={keyWords}
                      onChange={(e) => setKeyWords(e.target.value)}
                      disabled={!edit}
                    />
                    <span className="invalid-feedback">{errors.keyWords}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {edit ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            ) : null}
        </form>
      </div>
    </div>
  );
};

export default FAQsEdit;
