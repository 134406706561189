import { useState } from "react";
import { FixturesService } from "../../../services/fixtureService";
import FixtureUserDetails from "../../../components/Common/FixtureUserDetails";

const EditMatchResult = ({ fixtureId, match }) => {
  const [homeResult, sethomeResult] = useState(match.homeResult);
  const [homeResult2, sethomeResult2] = useState(match.homeResult2);
  const [awayResult, setawayResult] = useState(match.awayResult);
  const [awayResult2, setawayResult2] = useState(match.awayResult2);

  // textboxes for minutes and seconds
  const [homeMinutes, setHomeMinutes] = useState(
    Math.floor(match.homeResult / 60)
  );
  const [homeMinutes2, setHomeMinutes2] = useState(
    Math.floor(match.homeResult2 / 60)
  );
  const [awayMinutes, setAwayMinutes] = useState(
    Math.floor(match.awayResult / 60)
  );
  const [awayMinutes2, setAwayMinutes2] = useState(
    Math.floor(match.awayResult2 / 60)
  );

  const [homeSeconds, setHomeSeconds] = useState(match.homeResult % 60);
  const [homeSeconds2, setHomeSeconds2] = useState(match.homeResult2 % 60);
  const [awaySeconds, setAwaySeconds] = useState(match.awayResult % 60);
  const [awaySeconds2, setAwaySeconds2] = useState(match.awayResult2 % 60);

  const [errors, setErrors] = useState({
    homeResult: null,
    awayResult: null,
    homeResult2: null,
    awayResult2: null,
  });

  const [showSaveButton, setShowSaveButton] = useState(false);

  const submitResult = async () => {
    // check errors
    const homeResultIntValue = parseInt(homeResult);
    const awayResultIntValue = parseInt(awayResult);
    const homeResultIntValue2 = parseInt(homeResult2);
    const awayResultIntValue2 = parseInt(awayResult2);

    const validationErrors = {
      homeResult:
        isNaN(homeResultIntValue) || homeResultIntValue < 0
          ? "Debe ser un número mayor o igual que 0"
          : null,
      awayResult:
        isNaN(awayResultIntValue) || awayResultIntValue < 0
          ? "Debe ser un número mayor o igual que 0"
          : null,
      homeResult2:
        match.wod.qualifyType2 &&
        (isNaN(homeResultIntValue2) || homeResultIntValue2 < 0)
          ? "Debe ser un número mayor o igual que 0"
          : null,
      awayResult2:
        match.wod.qualifyType2 &&
        (isNaN(awayResultIntValue2) || awayResultIntValue2 < 0)
          ? "Debe ser un número mayor o igual que 0"
          : null,
    };
    setErrors(validationErrors);
    for (const key in validationErrors) {
      if (
        validationErrors.hasOwnProperty(key) &&
        validationErrors[key] !== null
      ) {
        return false;
      }
    }

    // TODO: Submit scores
    const response = await FixturesService.setMatchScore(
      fixtureId,
      match.id,
      homeResult,
      awayResult,
      homeResult2,
      awayResult2
    );

    if (response.ok) {
      setShowSaveButton(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3 className="font-weight-light">{match.wod.description}</h3>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-md-6">
          <FixtureUserDetails
            user={match.homeUser1}
            wod={match.wod}
            isHome={true}
          />
        </div>
        <div className="col-md-6">
          <FixtureUserDetails
            user={match.awayUser1}
            wod={match.wod}
            isHome={false}
          />
        </div>
      </div>
      {match.wod.gender2 && (
        <div className="row">
          <div className="col-md-6">
            <FixtureUserDetails
              user={match.homeUser2}
              wod={match.wod}
              isHome={true}
            />
          </div>
          <div className="col-md-6">
            <FixtureUserDetails
              user={match.awayUser2}
              wod={match.wod}
              isHome={false}
            />
          </div>
        </div>
      )}

      {match.wod.qualifyType2 && (
        <h4>
          Parte 1 -{" "}
          {match.wod.qualifyType == 1
            ? "FOR TIME"
            : match.wod.qualifyType == 2
            ? "AMRAP"
            : "RM"}
        </h4>
      )}
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="homeResult">
              {match.wod.qualifyType == 1
                ? "Tiempo"
                : match.wod.qualifyType == 2
                ? "Reps"
                : "KG"}{" "}
              <span class="text-danger">*</span>
            </label>
            {match.wod.qualifyType === 1 ? (
              <div className="input-group">
                <input
                  type="number"
                  className={
                    (errors.homeResult ? "is-invalid " : "") + "form-control"
                  }
                  id="homeMinutes"
                  min="0"
                  value={homeMinutes}
                  onChange={(e) => {
                    setHomeMinutes(e.target.value);
                    sethomeResult(
                      parseInt(e.target.value) * 60 + parseInt(homeSeconds)
                    );
                    setShowSaveButton(true);
                  }}
                />
                <div class="input-group-preppend">
                  <span class="input-group-text">Min</span>
                </div>
                <input
                  type="number"
                  className={
                    (errors.homeResult ? "is-invalid " : "") + "form-control"
                  }
                  id="homeSeconds"
                  min="0"
                  max="59"
                  value={homeSeconds}
                  onChange={(e) => {
                    setHomeSeconds(e.target.value);
                    sethomeResult(
                      parseInt(homeMinutes) * 60 + parseInt(e.target.value)
                    );
                    setShowSaveButton(true);
                  }}
                />
                <div class="input-group-append">
                  <span class="input-group-text">Seg</span>
                </div>
              </div>
            ) : (
              <div className="input-group">
                <input
                  type="number"
                  className={
                    (errors.homeResult ? "is-invalid " : "") + "form-control"
                  }
                  id="homeResult"
                  min="0"
                  value={homeResult}
                  onChange={(e) => {
                    sethomeResult(e.target.value);
                    setShowSaveButton(true);
                  }}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    {match.wod.qualifyType == 2 ? "Reps" : "KG"}
                  </span>
                </div>
              </div>
            )}
            <span className="invalid-feedback">{errors.homeResult}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="awayResult">
              {match.wod.qualifyType == 1
                ? "Tiempo"
                : match.wod.qualifyType == 2
                ? "Reps"
                : "KG"}{" "}
              <span class="text-danger">*</span>
            </label>
            {match.wod.qualifyType === 1 ? (
              <div className="input-group">
                <input
                  type="number"
                  className={
                    (errors.awayResult ? "is-invalid " : "") + "form-control"
                  }
                  id="awayMinutes"
                  min="0"
                  value={awayMinutes}
                  onChange={(e) => {
                    setAwayMinutes(e.target.value);
                    setawayResult(
                      parseInt(e.target.value) * 60 + parseInt(awaySeconds)
                    );
                    setShowSaveButton(true);
                  }}
                />
                <div class="input-group-preppend">
                  <span class="input-group-text">Min</span>
                </div>
                <input
                  type="number"
                  className={
                    (errors.awayResult ? "is-invalid " : "") + "form-control"
                  }
                  id="awaySeconds"
                  min="0"
                  max="59"
                  value={awaySeconds}
                  onChange={(e) => {
                    setAwaySeconds(e.target.value);
                    setawayResult(
                      parseInt(awayMinutes) * 60 + parseInt(e.target.value)
                    );
                    setShowSaveButton(true);
                  }}
                />
                <div class="input-group-append">
                  <span class="input-group-text">Seg</span>
                </div>
              </div>
            ) : (
              <div className="input-group">
                <input
                  type="number"
                  className={
                    (errors.awayResult ? "is-invalid " : "") + "form-control"
                  }
                  id="awayResult"
                  min="0"
                  value={awayResult}
                  onChange={(e) => {
                    setawayResult(e.target.value);
                    setShowSaveButton(true);
                  }}
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    {match.wod.qualifyType == 2 ? "Reps" : "KG"}
                  </span>
                </div>
              </div>
            )}
            <span className="invalid-feedback">{errors.awayResult}</span>
          </div>
        </div>
      </div>
      {match.wod.qualifyType2 && (
        <>
          <h4>
            Parte 2 -{" "}
            {match.wod.qualifyType2 == 1
              ? "FOR TIME"
              : match.wod.qualifyType2 == 2
              ? "AMRAP"
              : "RM"}
          </h4>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="homeResult2">
                  {match.wod.qualifyType2 == 1
                    ? "Tiempo"
                    : match.wod.qualifyType2 == 2
                    ? "Reps"
                    : "KG"}{" "}
                  <span class="text-danger">*</span>
                </label>
                {match.wod.qualifyType2 === 1 ? (
                  <div className="input-group">
                    <input
                      type="number"
                      className={
                        (errors.homeResult2 ? "is-invalid " : "") +
                        "form-control"
                      }
                      id="homeMinutes2"
                      min="0"
                      value={homeMinutes2}
                      onChange={(e) => {
                        setHomeMinutes2(e.target.value);
                        sethomeResult2(
                          parseInt(e.target.value) * 60 + parseInt(homeSeconds2)
                        );
                        setShowSaveButton(true);
                      }}
                    />
                    <div class="input-group-preppend">
                      <span class="input-group-text">Min</span>
                    </div>
                    <input
                      type="number"
                      className={
                        (errors.homeResult2 ? "is-invalid " : "") +
                        "form-control"
                      }
                      id="homeSeconds2"
                      min="0"
                      max="59"
                      value={homeSeconds2}
                      onChange={(e) => {
                        setHomeSeconds2(e.target.value);
                        sethomeResult2(
                          parseInt(homeMinutes2) * 60 + parseInt(e.target.value)
                        );
                        setShowSaveButton(true);
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Seg</span>
                    </div>
                  </div>
                ) : (
                  <div className="input-group">
                    <input
                      type="number"
                      className={
                        (errors.homeResult2 ? "is-invalid " : "") +
                        "form-control"
                      }
                      id="homeResult2"
                      min="0"
                      value={homeResult2}
                      onChange={(e) => {
                        sethomeResult2(e.target.value);
                        setShowSaveButton(true);
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        {match.wod.qualifyType == 2 ? "Reps" : "KG"}
                      </span>
                    </div>
                  </div>
                )}
                <span className="invalid-feedback">{errors.homeResult2}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="awayResult2">
                  {match.wod.qualifyType2 === 1
                    ? "Tiempo"
                    : match.wod.qualifyType2 === 2
                    ? "Reps"
                    : "KG"}{" "}
                  <span class="text-danger">*</span>
                </label>

                {match.wod.qualifyType2 === 1 ? (
                  <div className="input-group">
                    <input
                      type="number"
                      className={
                        (errors.awayResult2 ? "is-invalid " : "") +
                        "form-control"
                      }
                      id="awayMinutes2"
                      min="0"
                      value={awayMinutes2}
                      onChange={(e) => {
                        setAwayMinutes2(e.target.value);
                        setawayResult2(
                          parseInt(e.target.value) * 60 + parseInt(awaySeconds2)
                        );
                        setShowSaveButton(true);
                      }}
                    />
                    <div class="input-group-preppend">
                      <span class="input-group-text">Min</span>
                    </div>
                    <input
                      type="number"
                      className={
                        (errors.awayResult2 ? "is-invalid " : "") +
                        "form-control"
                      }
                      id="awaySeconds2"
                      min="0"
                      max="59"
                      value={awaySeconds2}
                      onChange={(e) => {
                        setAwaySeconds2(e.target.value);
                        setawayResult2(
                          parseInt(awayMinutes2) * 60 + parseInt(e.target.value)
                        );
                        setShowSaveButton(true);
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Seg</span>
                    </div>
                  </div>
                ) : (
                  <div className="input-group">
                    <input
                      type="number"
                      className={
                        (errors.awayResult2 ? "is-invalid " : "") +
                        "form-control"
                      }
                      id="awayResult2"
                      min="0"
                      value={awayResult2}
                      onChange={(e) => {
                        setawayResult2(e.target.value);
                        setShowSaveButton(true);
                      }}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        {match.wod.qualifyType == 2 ? "Reps" : "KG"}
                      </span>
                    </div>
                  </div>
                )}
                <span className="invalid-feedback">{errors.awayResult2}</span>
              </div>
            </div>
          </div>
        </>
      )}
      {showSaveButton && (
        <div className="row">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={submitResult}
            >
              Guardar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditMatchResult;
