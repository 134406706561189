import { useState, useMemo, useEffect } from "react";
import { AuthenticationService } from "./services/authenticationService";
import Login from "./screens/Login";

import { AuthContext } from "./helpers/AuthContext";
import Dashboard from "./screens/Dashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Users from "./screens/Users";
import Accounts from "./screens/Accounts";
import UserView from "./screens/UserView";
import SubscriptionRequest from "./screens/SubscriptionRequest";
import FAQs from "./screens/FAQs";
import Boxes from "./screens/Boxes";
import Newsletter from "./screens/Newsletter";
import Divisions from "./screens/Divisions";
import Categories from "./screens/Categories";
import Territories from "./screens/Territories";
import Groups from "./screens/Groups";
import Teams from "./screens/Teams";
import Exercises from "./screens/Exercises";
import Wods from "./screens/Wods";
import Statistics from "./screens/Statistics";
import Comunications from "./screens/Comunications";
import Fixture from "./screens/Fixture";
import GroupView from "./screens/GroupView";
import TeamView from "./screens/TeamView";
import Email from "./screens/Email";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    var apiSessionId = localStorage.getItem("apiSesionId");
    setUserToken(apiSessionId);
    if (!apiSessionId || apiSessionId.trim().length === 0) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  const authenticationMemo = useMemo(
    () => ({
      signIn: async (login, password) => {
        return AuthenticationService.login(login, password).then(
          async (response) => {
            if (response.ok) {
              return await response.json().then((result) => {
                localStorage.setItem("apiSesionId", result.token);
                setIsLoggedIn(true);
                setUserToken(result.token);
                return { success: true, message: "" };
              });
            }
            return {
              success: false,
              message: "No existe el usuario o la contraseña es incorrecta.",
            };
          }
        );
      },
      signOut: () => {
        localStorage.removeItem("apiSesionId");
        setIsLoggedIn(false);
        setUserToken(null);
      },
    }),
    []
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
    },
    { path: "/deportistas", element: <Users /> },
    { path: "/usuarios", element: <Accounts /> },
    { path: "/deportistas/:userId", element: <UserView /> },
    { path: "/subscriptionRequest", element: <SubscriptionRequest /> },
    { path: "/faqs", element: <FAQs /> },
    { path: "/newsletter", element: <Newsletter /> },
    { path: "/boxes", element: <Boxes /> },
    { path: "/equipos", element: <Teams /> },
    { path: "/divisions", element: <Divisions /> },
    { path: "/territories", element: <Territories /> },
    { path: "/categories", element: <Categories /> },
    { path: "/groups", element: <Groups /> },
    { path: "/exercises", element: <Exercises /> },
    { path: "/wods", element: <Wods /> },
    { path: "/statistics", element: <Statistics /> },
    { path: "/comunications", element: <Comunications /> },
    { path: "/email", element: <Email /> },
    { path: "/fixture/:fixtureid", element: <Fixture /> },
    { path: "/grupo/:groupid", element: <GroupView /> },
    { path: "/equipo/:teamid", element: <TeamView /> },
  ]);

  return (
    <div className="App">
      <AuthContext.Provider value={authenticationMemo}>
        {isLoggedIn ? <RouterProvider router={router} /> : <Login />}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
