import { useNavigate } from "react-router-dom";
import { UrlBase } from "../../../helpers/constants";

const FixtureUserDetails = ({ user, wod, isHome }) => {
  const navigate = useNavigate();

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className={`user-block ${isHome === false ? "float-right" : ""}`}>
          <img
            className="img-circle"
            src={
              user?.profilePic
                ? `${UrlBase}/profile-pics/${user.profilePic}`
                : "/dist/img/user0-128x128.png"
            }
            alt="user image"
          />
          {user ? (
            <span className="username">
              <a
                href="#"
                onClick={() => navigate("/deportistas/" + user.id)}
              >{`${user.name} ${user.surname}`}</a>
            </span>
          ) : (
            <span className="username">No Seleccionado</span>
          )}
          <span className="description">{wod.description}</span>
        </div>
      </div>
    </div>
  );
};

export default FixtureUserDetails;
