import { useEffect, useRef, useState } from "react";
import { MiscService } from "../../../services/miscService";
import { TeamsService } from "../../../services/teamsService";
import { UsersService } from "../../../services/usersService";

const UserEdit = ({ user }) => {
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [teams, setTeams] = useState([]);

  const [name, setName] = useState(user?.name);
  const [surname, setSurname] = useState(user?.surname);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [personalId, setPersonalId] = useState(user?.personalId);
  const [addressName, setAddressName] = useState(user?.addressName);
  const [addressNumber, setAddressNumber] = useState(user?.addressNumber);
  const [addressOther, setAddressOther] = useState(user?.addressOther);
  const [zipCode, setZipCode] = useState(user?.zipCode);
  const [provinceId, setProvinceId] = useState(user?.provinceId);
  const [cityId, setCityId] = useState(user?.cityId);
  const [teamId, setTeamId] = useState(user?.teamId);

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const switchEditRef = useRef(null);

  // Load provinces, cities and teams
  useEffect(() => {
    const loadProvinces = async () => {
      const response = await MiscService.getAllProvinces();
      const provinces = await response.json();
      setProvinces(provinces);
    };

    const loadCities = async () => {
      const response = await MiscService.getAllCities();
      const cities = await response.json();
      setCities(cities);
    };

    const loadTeams = async () => {
      const response = await TeamsService.getAll();
      const teams = await response.json();
      setTeams(teams);
    };

    const loaders = [loadProvinces(), loadCities(), loadTeams()];
    Promise.all(loaders).then(() => setLoading(false));
  }, []);

  // Reload cities when provinceId changes
  useEffect(() => {
    const loadCities = async () => {
      const response = await MiscService.getCitiesByProvinceId(provinceId);
      const cities = await response.json();
      setCities(cities);

      // If the cityId is not in the new cities list, set it to 0
      if (!cities.some((city) => city.id === cityId)) {
        setCityId(0);
      }
    };

    if (provinceId) {
      loadCities();
    }
  }, [provinceId]);

  // validate name
  const validateName = () => {
    if (name.length === 0) {
      return "El nombre es obligatorio.";
    } else if (name.length < 3) {
      return "El nombre debe tener al menos 3 caracteres.";
    } else if (name.length > 50) {
      return "El nombre debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate surname
  const validateSurname = () => {
    if (surname.length === 0) {
      return "El apellido es obligatorio.";
    } else if (surname.length < 3) {
      return "El apellido debe tener al menos 3 caracteres.";
    } else if (surname.length > 50) {
      return "El apellido debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate addressName
  const validateAddressName = () => {
    if (addressName.length === 0) {
      return "La calle es obligatoria.";
    } else if (addressName.length < 3) {
      return "La calle debe tener al menos 3 caracteres.";
    } else if (addressName.length > 50) {
      return "La calle debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate addressNumber
  const validateAddressNumber = () => {
    if (addressNumber.length === 0) {
      return "El número es obligatorio.";
    } else if (addressNumber.length < 1) {
      return "El número debe tener al menos 1 caracteres.";
    } else if (addressNumber.length > 10) {
      return "El número debe tener menos de 10 caracteres.";
    } else {
      return null;
    }
  };

  // validate addressOther
  const validateAddressOther = () => {
    if (addressOther.length > 50) {
      return "El resto de la dirección debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate provinceId
  const validateProvinceId = () => {
    if (provinceId === 0 || provinceId === "0") {
      return "La provincia es obligatoria.";
    } else {
      return null;
    }
  };

  // validate cityId
  const validateCityId = () => {
    if (cityId === 0 || cityId === "0") {
      return "La ciudad es obligatoria.";
    } else {
      return null;
    }
  };

  // validate zipCode
  const validateZipCode = () => {
    if (zipCode.length === 0) {
      return "El código postal es obligatorio.";
    } else if (zipCode.length !== 5) {
      return "El código postal debe tener 5 caracteres.";
    } else {
      return null;
    }
  };

  // validate phone number. It must be a valid spanish phone number
  const validatePhoneNumber = () => {
    if (phoneNumber.length === 0) {
      return "El teléfono es obligatorio.";
    } else if (phoneNumber.length !== 9) {
      return "El teléfono debe tener 9 caracteres.";
    } else if (!/^[6|7|9][0-9]{8}$/.test(phoneNumber)) {
      return "El teléfono debe ser un número de teléfono español válido.";
    } else {
      return null;
    }
  };

  // validate personalId. It must be a valid spanish NIF or NIE
  const validatePersonalId = () => {
    if (personalId.length === 0) {
      return "El DNI/NIE es obligatorio.";
    } else if (personalId.length !== 9) {
      return "El DNI/NIE debe tener 9 caracteres.";
    } else if (
      !/^[0-9]{8}[A-Z]$/.test(personalId) &&
      !/^[XYZ][0-9]{7}[A-Z]$/.test(personalId)
    ) {
      return "El DNI/NIE debe ser un número de DNI/NIE español válido.";
    } else {
      return null;
    }
  };

  // validate all
  const validateAll = () => {
    const formErrors = {
      name: validateName(),
      surname: validateSurname(),
      addressName: validateAddressName(),
      addressNumber: validateAddressNumber(),
      addressOther: validateAddressOther(),
      provinceId: validateProvinceId(),
      cityId: validateCityId(),
      zipCode: validateZipCode(),
      phoneNumber: validatePhoneNumber(),
      personalId: validatePersonalId(),
    };

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    if (Object.values(errors).every((x) => x === null)) {
      const updateUser = async () => {
        const response = await UsersService.updateUser(
          user.id,
          name,
          surname,
          personalId,
          phoneNumber,
          addressName,
          addressNumber,
          addressOther,
          zipCode,
          cityId,
          provinceId,
          isNaN(parseInt(teamId)) ? null : parseInt(teamId)
        );

        if (response.ok) {
          switchEditRef.current.checked = false;
          setEdit(false);
          // show success message
        } else {
          // show error message
        }
      };

      updateUser();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                value={edit}
                ref={switchEditRef}
                onChange={(e) => setEdit(e.target.checked)}
                class="custom-control-input"
                id="edit"
              />
              <label class="custom-control-label" for="edit">
                Activar el modo edición
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">
                    Nombre <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.name ? "is-invalid " : "") + "form-control"
                    }
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.name}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="surname">
                    Apellidos <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.surname ? "is-invalid " : "") + "form-control"
                    }
                    id="surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.surname}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="phoneNumber">
                    Teléfono <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.phoneNumber ? "is-invalid " : "") + "form-control"
                    }
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.phoneNumber}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="personalId">
                    DNI/NIE <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.personalId ? "is-invalid " : "") + "form-control"
                    }
                    id="personalId"
                    value={personalId}
                    onChange={(e) => setPersonalId(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.personalId}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="addressName">
                    Dirección (Tipo y nombre de vía){" "}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressName ? "is-invalid " : "") + "form-control"
                    }
                    id="addressName"
                    value={addressName}
                    onChange={(e) => setAddressName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.addressName}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="addressNumber">
                    Número <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressNumber ? "is-invalid " : "") +
                      "form-control"
                    }
                    id="addressNumber"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">
                    {errors.addressNumber}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="addressOther">
                    Escalera, Piso, Puerta...
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressOther ? "is-invalid " : "") +
                      "form-control"
                    }
                    id="addressOther"
                    value={addressOther}
                    onChange={(e) => setAddressOther(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">
                    {errors.addressOther}
                  </span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="zipCode">
                    Código postal <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressOther ? "is-invalid " : "") +
                      "form-control"
                    }
                    id="zipCode"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.zipCode}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="provinceId">
                    Provincia <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.provinceId ? "is-invalid " : "") + "form-control"
                    }
                    id="provinceId"
                    value={provinceId}
                    onChange={(e) => setProvinceId(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una provincia</option>
                    {provinces.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.provinceId}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="cityId">
                    Ciudad <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.cityId ? "is-invalid " : "") + "form-control"
                    }
                    id="cityId"
                    value={cityId}
                    onChange={(e) => setCityId(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una ciudad --</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.cityId}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="teamId">Equipo</label>
                  <select
                    className="form-control"
                    id="teamId"
                    value={teamId}
                    onChange={(e) => setTeamId(e.target.value)}
                    disabled={!edit}
                  >
                    <option>-- Selecciona un equipo --</option>
                    {teams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6"></div>
            </div>

            {edit ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
