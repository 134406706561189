import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { CategoriesService } from "../../services/categoriesService";
import CategoryEdit from "./CategoryEdit";
import Table from "../../components/Common/Table/Table";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [modalCategories, setModalCategories] = useState(null);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [titleModal, setTitleModal] = useState("Editar categoría");

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllCategories = async () => {
    const response = await CategoriesService.getAll();
    if (response.ok) {
      const result = await response.json();
      setCategories(result);
      setIsLoading(false);
    } else {
      return {
        success: false,
        message: "No se han podido obtener las categorías de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (modalCategories) {
      setShowCategoryModal(true);
    } else {
      setShowCategoryModal(false);
    }
  }, [modalCategories]);

  useEffect(() => {
    if (categories.length > 0) initDataTables();
  }, [categories]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblCategories")) {
      window.$("#tblCategories").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2],
            className: "text-center",
          },
        ],
      });
    }
  };

  const deleteCategory = async (category) => {
    // confirm if sure to delete
    const confirm = window.confirm(
      '¿Está seguro de eliminar la categoría "' + category.name + '"?'
    );
    if (!confirm) return;

    const response = await CategoriesService.deleteCategory(category.id);
    if (response.ok) {
      getAllCategories();
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Categorías", url: "/categories" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Categorías"
                      value={categories.length}
                      icon="fas fa-question mr-1"
                    />
                  </div>
                  <div className="col-8"></div>
                  <div className="col-1 text-right">
                    <button
                      class="btn btn-app bg-info mt-5"
                      onClick={() => {
                        setModalCategories({});
                        setIsNewCategory(true);
                        setTitleModal("Nueva categoría");
                      }}
                    >
                      <i class="fas fa-plus"></i> Agregar
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    {!isLoading ? (
                      <div className="card card-info">
                        <div className="card-header">
                          <h3 className="card-title">Lista de categorías</h3>
                        </div>
                        <div className="card-body">
                          <Table
                            data={categories}
                            fields={[
                              { objectField: "name", titleField: "Nombre" },
                              {
                                objectField: "description",
                                titleField: "Descripcion",
                              },
                            ]}
                            actions={[
                              {
                                title: "Editar",
                                ico: "fas fa-edit",
                                class: "warning",
                                action: (obj) => {
                                  setModalCategories(obj);
                                  setIsNewCategory(false);
                                  setTitleModal("Editar división");
                                },
                              },
                              {
                                title: "Borrar",
                                ico: "fas fa-trash",
                                class: "danger",
                                action: (obj) => {
                                  deleteCategory(obj);
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
      <Modal
        show={showCategoryModal}
        handleClose={() => {
          setModalCategories(null);
          setShowCategoryModal(false);
          getAllCategories();
        }}
        body={
          modalCategories ? (
            <CategoryEdit
              category={modalCategories}
              newCategory={isNewCategory}
            />
          ) : null
        }
        title={titleModal}
      />
    </>
  );
};

export default Categories;
