import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/actions", requestOptions);
}

async function _getByTeam(teamId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/actions/team/" + teamId, requestOptions);
}

async function _getByUser(userId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/actions/user/" + userId, requestOptions);
}

async function _getByFixture(fixtureId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/actions/fixture/" + fixtureId, requestOptions);
}

export const ActionsService = {
  getAll: _getAll,
  getByTeam: _getByTeam,
  getByUser: _getByUser,
  getByFixture: _getByFixture,
};
