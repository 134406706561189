import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Table from "../../components/Common/Table";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";

const Statistics = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Estadísticas", url: "/Statistics" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <h1>En desarrollo</h1>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};

export default Statistics;
