import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/newsletter-subscriptions", requestOptions);
}

async function _unsuscribe(unsubscriptionToken) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify(unsubscriptionToken),
  };

  return fetch(ApiUrlBase + "/newsletter-subscriptions", requestOptions);
}

async function _download() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(
    ApiUrlBase + "/newsletter-subscriptions/subscribers?csv=true",
    requestOptions
  );
}

export const NewsletterService = {
  getAll: _getAll,
  unsuscribe: _unsuscribe,
  download: _download,
};
