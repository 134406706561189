import moment from "moment";
import { useNavigate } from "react-router-dom";

const FixtureItem = ({ fixture, showWeek }) => {
  const navigate = useNavigate();
  var calloutClass = "callout callout-info";
  switch (fixture.status) {
    case "Finished":
      calloutClass = "callout callout-success";
      break;
    case "Scheduled":
      calloutClass = "callout callout-warning";
      break;
    default:
      calloutClass = "callout callout-danger";
      break;
  }

  // initialize showWeek if nor provided
  if (showWeek === undefined) {
    showWeek = false;
  }

  return (
    <div className={calloutClass}>
      {showWeek && (
        <div className="row">
          <div className="col">
            <h5 className="font-weight-light">Week {fixture.week}</h5>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-5">
          <a href="#" onClick={() => navigate("/equipo/" + fixture.homeTeamId)}>
            <b>{fixture.homeTeam.name}</b>
          </a>
        </div>
        <div className="col-2">
          {fixture.status === "Finished" ? (
            <h4>
              {fixture.homeScore} : {fixture.awayScore}
            </h4>
          ) : (
            <h4>- : -</h4>
          )}
        </div>
        <div className="col-5">
          <a href="#" onClick={() => navigate("/equipo/" + fixture.awayTeamId)}>
            <b>{fixture.awayTeam.name}</b>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-6">{fixture.status}</div>
        <div className="col-6">
          {fixture.date
            ? moment(new Date(fixture.date + "z")).format("DD/MM/YYYY HH:mm")
            : ""}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-22">
          <a href="#" onClick={() => navigate("/fixture/" + fixture.id)}>
            <i className="fas fa-info-circle"></i> Detalles
          </a>
        </div>
      </div>
    </div>
  );
};

export default FixtureItem;
