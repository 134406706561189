import { useEffect, useState } from "react";

const Table = ({
  fields,
  data,
  actions,
  tblClass,
  rowClassFunction,
  options,
}) => {
  var hasActionButtons =
    actions === null || actions === undefined ? false : true;
  var id = "tbl-" + Math.random().toString(36).substring(7);

  var renderRowClass = (row) => {
    if (rowClassFunction === null || rowClassFunction === undefined) return "";
    return rowClassFunction(row);
  };

  useEffect(() => {
    if (data && data.length > 0) initDataTables();
  }, [data]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#" + id)) {
      window.$("#" + id).DataTable({
        scrollX: true,
        autoWidth: true,
        ordering: options?.ordering,
        paging: options?.paging,
        searching: options?.searching,
        info: options ? options.info : true,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: Array.from({ length: fields.length }, (_, i) => i),
            className: "text-center",
          },
        ],
      });
    }
  };

  return (
    <table
      id={id}
      className={
        "table " +
        (tblClass === null || tblClass === undefined ? "table-hover" : tblClass)
      }
    >
      <thead>
        <tr>
          {fields.map((field) => {
            return (
              <>
                <th>{field.titleField}</th>
              </>
            );
          })}
          {hasActionButtons ? <th className="text-center">Acciones</th> : null}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => {
          return (
            <tr className={renderRowClass(row)}>
              {fields.map((field) => {
                const keys = field.objectField.split(".");
                let value = row;
                for (const key of keys) {
                  value = value?.[key];
                }
                return (
                  <td style={{ textAlign: "center" }}>
                    {value !== undefined ? value : ""}
                  </td>
                );
              })}

              {hasActionButtons ? (
                <td className="text-center">
                  {actions.map((action) => {
                    return (
                      <button
                        className={"mr-1 btn btn-" + action.class} //btn-sm
                        title={action.title}
                        onClick={() => {
                          action.action(row);
                        }}
                      >
                        <i className={action.ico}></i>
                      </button>
                    );
                  })}
                </td>
              ) : null}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
