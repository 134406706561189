import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Table from "../../components/Common/Table";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { UsersService } from "../../services/usersService";
import UserEdit from "./UserEdit";

const Users = () => {
  const [users, setUsers] = useState([]);

  const [showUserModal, setShowUserModal] = useState(false);
  const [modalUser, setModalUser] = useState(null);

  const [isNewUser, setIsNewUser] = useState(null);
  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllUSers = async () => {
    const response = await UsersService.getAll();
    if (response.ok) {
      const result = await response.json();
      setUsers(result);
    } else {
      return {
        success: false,
        message: "No se han podido obtener los usuarios de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllUSers();
  }, []);

  useEffect(() => {
    if (modalUser) setShowUserModal(true);
    else setShowUserModal(false);
  }, [modalUser]);

  useEffect(() => {
    if (users.length > 0) initDataTables();
  }, [users]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblUsers")) {
      window.$("#tblUsers").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2, 3, 4, 5],
            className: "text-center",
          },
        ],
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {users.length > 0 ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Atletas", url: "/deportistas" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Atletas registrados"
                      value={users.length}
                      icon="fas fa-running"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Registros de atletas</h3>
                      </div>
                      <div className="card-body">
                        <Table
                          data={users}
                          fields={[
                            { objectField: "name", titleField: "Nombre" },
                            { objectField: "surname", titleField: "Apellidos" },
                            {
                              objectField: "account.email",
                              titleField: "Email",
                            },
                            { objectField: "team.name", titleField: "Equipo" },
                            { objectField: "team.box.name", titleField: "Box" },
                            {
                              objectField: "province.name",
                              titleField: "Provincia",
                            },
                          ]}
                          actions={[
                            {
                              title: "Editar",
                              ico: "fas fa-edit",
                              class: "warning",
                              action: (obj) => {
                                setModalUser(obj);
                                setIsNewUser(false);
                                // setTitleModal("Editar división");
                              },
                            },
                            {
                              title: "Borrar",
                              ico: "fas fa-trash",
                              class: "danger",
                              action: (obj) => {
                                // delete(obj);
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />

      <Modal
        show={showUserModal}
        handleClose={() => {
          getAllUSers().then(() => {
            setModalUser(null);
            setShowUserModal(false);
          });
        }}
        body={modalUser ? <UserEdit user={modalUser} /> : null}
        title={"Datos de " + modalUser?.name + " " + modalUser?.surname}
      />
    </>
  );
};

export default Users;
