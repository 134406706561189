import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../helpers/AuthContext";
import logo from "../../logo.png";

const Login = () => {
  useEffect(() => {
    document.body.className = "hold-transition login-page";
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [hasLoginError, setHasLoginError] = useState(false);
  const { signIn } = useContext(AuthContext);

  const doSignIn = () => {
    signIn(email, password).then((result) => {
      console.log(result);
      if (!result.success) {
        setLoginErrorMessage(result.message);
        setHasLoginError(true);
      } else {
        setLoginErrorMessage("");
        setHasLoginError(false);
      }
    });
  };

  return (
    <>
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <div className="d-flex justify-content-center">
              <img src={logo} height="50" alt="logo" className="auth-logo" />
            </div>
            <div className="d-flex justify-content-center">
              <a href="../../index2.html" className="h1">
                <b>Admin</b>BOX
              </a>
            </div>
          </div>
          <div className="card-body">
            <p className="login-box-msg">
              Accede al sistema de administración de NCL
            </p>

            <form>
              <div className="form-group">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Introduce tu email"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div
                  className={
                    (hasLoginError ? "is-invalid " : "") + "input-group mb-3"
                  }
                >
                  <input
                    type="password"
                    className={
                      (hasLoginError ? "is-invalid " : "") + "form-control"
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Introduce tu password"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <span className="invalid-feedback">{loginErrorMessage}</span>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Recordar</label>
                  </div>
                </div>

                <div className="col-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={doSignIn}
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>

            <p className="mb-1">
              <a href="forgot-password.html">Olvidé mi contraseña</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
