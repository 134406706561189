import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { ExercisesService } from "../../services/exercisesService";
import CategoryEdit from "./ExerciseEdit";
import Table from "../../components/Common/Table/Table";
import ExerciseEdit from "./ExerciseEdit";

const Exercises = () => {
  const [exercises, setExercises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showExerciseModal, setShowExerciseModal] = useState(false);
  const [modalExercises, setModalExercises] = useState(null);
  const [isNewExercise, setIsNewExercise] = useState(false);
  const [titleModal, setTitleModal] = useState("Editar Ejercicio");

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllExercises = async () => {
    const response = await ExercisesService.getAll();
    if (response.ok) {
      const result = await response.json();
      setExercises(result);
      setIsLoading(false);
    } else {
      return {
        success: false,
        message: "No se han podido obtener los ejercicios de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllExercises();
  }, []);

  useEffect(() => {
    if (modalExercises) {
      setShowExerciseModal(true);
    } else {
      setShowExerciseModal(false);
    }
  }, [modalExercises]);

  useEffect(() => {
    if (exercises.length > 0) initDataTables();
  }, [exercises]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblExercises")) {
      window.$("#tblExercises").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2],
            className: "text-center",
          },
        ],
      });
    }
  };

  const deleteExercise = async (exercise) => {
    // confirm if sure to delete
    const confirm = window.confirm(
      '¿Está seguro de eliminar el ejercicio "' + exercise.name + '"?'
    );
    if (!confirm) return;

    const response = await ExercisesService.deleteExercise(exercise.id);
    if (response.ok) {
      getAllExercises();
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Ejercicios", url: "/exercises" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Ejercicios"
                      value={exercises.length}
                      icon="fas fa-question mr-1"
                    />
                  </div>
                  <div className="col-8"></div>
                  <div className="col-1 text-right">
                    <button
                      class="btn btn-app bg-info mt-5"
                      onClick={() => {
                        setModalExercises({});
                        setIsNewExercise(true);
                        setTitleModal("Nuevo ejercicio");
                      }}
                    >
                      <i class="fas fa-plus"></i> Agregar
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    {!isLoading ? (
                      <div className="card card-info">
                        <div className="card-header">
                          <h3 className="card-title">Lista de ejercicios</h3>
                        </div>
                        <div className="card-body">
                          <Table
                            data={exercises}
                            fields={[
                              { objectField: "name", titleField: "Nombre" },
                              {
                                objectField: "description",
                                titleField: "Descripcion",
                              },
                            ]}
                            actions={[
                              {
                                title: "Editar",
                                ico: "fas fa-edit",
                                class: "warning",
                                action: (obj) => {
                                  setModalExercises(obj);
                                  setIsNewExercise(false);
                                  setTitleModal("Editar Ejercio");
                                },
                              },
                              {
                                title: "Borrar",
                                ico: "fas fa-trash",
                                class: "danger",
                                action: (obj) => {
                                  deleteExercise(obj);
                                },
                              },
                              {
                                title: "Ver ejercicio",
                                ico: "fas fa-eye",
                                class: "success",
                                action: (obj) => {
                                  window.open(obj.link, "_blank");
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
      <Modal
        show={showExerciseModal}
        handleClose={() => {
          setModalExercises(null);
          setShowExerciseModal(false);
          getAllExercises();
        }}
        body={
          modalExercises ? (
            <ExerciseEdit
              exercise={modalExercises}
              newExercise={isNewExercise}
            />
          ) : null
        }
        title={titleModal}
      />
    </>
  );
};

export default Exercises;
