import { ApiUrlBase } from "../helpers/constants";

async function _login(user, password) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: user,
      password: password,
      origin: "adminbox",
    }),
  };

  return fetch(ApiUrlBase + "/login", requestOptions);
}

export const AuthenticationService = {
  login: _login,
};
