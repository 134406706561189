import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SimpleItem from "../SimpleItem";

const TreeItem = ({ name, faIcon, badge, badgeClass, children, opened }) => {
  const [isOpened, setIsOpened] = useState(opened);

  const location = useLocation();

  useEffect(() => {
    //check if any of the children's links match the current location
    setIsOpened(children.find((item) => item.link == location.pathname));
  }, [location]);

  return (
    <li
      className={
        "nav-item " + (isOpened != null && isOpened ? "menu-open" : "")
      }
    >
      <Link
        to="#"
        className={"nav-link " + (isOpened != null && isOpened ? "active" : "")}
      >
        <i
          className={"nav-icon " + (faIcon == null ? "far fa-circle" : faIcon)}
        ></i>
        <p>
          {name}
          {badge != null && badgeClass != null ? (
            <span className={"right badge badge-" + badgeClass}>{badge}</span>
          ) : (
            <></>
          )}
          <i className="right fas fa-angle-left"></i>
        </p>
      </Link>
      <ul className="nav nav-treeview">
        {children.map((item, i) => {
          return (
            <SimpleItem
              name={item.name}
              link={item.link}
              faIcon={item.faIcon}
              badge={item.badge}
              badgeClass={item.badgeClass}
              active={i == 0 && isOpened != null && isOpened}
              key={i}
            />
          );
        })}
      </ul>
    </li>
  );
};
export default TreeItem;
