import { useEffect, useState } from "react";
import moment from "moment";
import Title from "../../components/Common/Title/Title";
import FixtureItem from "../../components/Common/FixtureItem";
import Widget from "../../components/Common/Widget/Widget";
import Table from "../../components/Common/Table";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { TeamsService } from "../../services/teamsService";
import { GroupService } from "../../services/groupService";
import { FixturesService } from "../../services/fixtureService";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [allTeams, setAllTeams] = useState([]);
  const [allFixtures, setAllFixtures] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allFixtureWindows, setAllFixtureWindows] = useState([]);

  const [nextFixtureWindow, setNextFixtureWindow] = useState(null);
  const [selectedFixtureWindow, setSelectedFixtureWindow] = useState(null);
  const [hasNextWeek, setHasNextWeek] = useState(false);
  const [hasPreviousWeek, setHasPreviousWeek] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupStanding, setSelectedGroupStanding] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTeams = async () => {
      const response = await TeamsService.getAll();
      if (response.ok) {
        const result = await response.json();
        setAllTeams(result);
      } else {
        return {
          success: false,
          message: "No se han podido obtener los equipos de base de datos.",
        };
      }
    };

    const getFixtures = async () => {
      const response = await FixturesService.getAll();
      if (response.ok) {
        const result = await response.json();
        setAllFixtures(result);
      } else {
        return {
          success: false,
          message: "No se han podido obtener los partidos de base de datos.",
        };
      }
    };

    const getAllFixtureWindows = async () => {
      const response = await FixturesService.getAllFixtureWindows();
      if (response.ok) {
        const result = await response.json();
        setAllFixtureWindows(result);
        // get current fixture window (window with nearest end date after today)
        const currentFixtureWindow = result.find(
          (fw) => new Date(fw.endDate) > new Date()
        );

        setNextFixtureWindow(
          currentFixtureWindow
            ? currentFixtureWindow
            : result[result.length - 1]
        );
        setSelectedFixtureWindow(
          currentFixtureWindow
            ? currentFixtureWindow
            : result[result.length - 1]
        );
      } else {
        return {
          success: false,
          message:
            "No se han podido obtener las ventanas de partidos de base de datos.",
        };
      }
    };

    const getGroups = async () => {
      const response = await GroupService.getAll();
      if (response.ok) {
        const result = await response.json();
        setAllGroups(result);
        setSelectedGroup(result[0]);
      } else {
        return {
          success: false,
          message: "No se han podido obtener los grupos de base de datos.",
        };
      }
    };

    // await for all promises to resolve
    Promise.all([
      getTeams(),
      getFixtures(),
      getGroups(),
      getAllFixtureWindows(),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      const getGroupStanding = async () => {
        const response = await GroupService.getGroupStandings(selectedGroup.id);
        if (response.ok) {
          const result = await response.json();

          console.log(result);
          setSelectedGroupStanding(result);
        } else {
          return {
            success: false,
            message:
              "No se han podido obtener la clasificación de base de datos.",
          };
        }
      };
      getGroupStanding();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedFixtureWindow) {
      setHasPreviousWeek(selectedFixtureWindow.weekNumber > 1);

      // finw max week number
      const maxWeekNumber = Math.max.apply(
        Math,
        allFixtureWindows.map(function (o) {
          return o.weekNumber;
        })
      );

      setHasNextWeek(selectedFixtureWindow.weekNumber < maxWeekNumber);
    }
  }, [selectedFixtureWindow]);

  const handleNextWeek = () => {
    const nextFixtureWindow = allFixtureWindows.find(
      (fw) => fw.weekNumber === selectedFixtureWindow.weekNumber + 1
    );
    setSelectedFixtureWindow(nextFixtureWindow);
  };

  const handlePreviousWeek = () => {
    const previousFixtureWindow = allFixtureWindows.find(
      (fw) => fw.weekNumber === selectedFixtureWindow.weekNumber - 1
    );
    setSelectedFixtureWindow(previousFixtureWindow);
  };

  const handleGroupSelection = (e) => {
    var selectedGroup = allGroups.find((g) => g.id == e.target.value);
    setSelectedGroup(selectedGroup);
  };
  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
    // // Add JS for demo purposes

    var visitorsData = {
      "ES-M": 398, // Madrid
      "ES-CA": 400, // Cadiz
      "ES-B": 1000, // Barcelona
      "ES-V": 500, // Valencia
      "ES-BI": 760, // Bizkaia
      "ES-MA": 300, // Malaga
      "ES-SE": 700, // Sevilla
    };

    window.$("#world-map").vectorMap({
      map: "es_mill",
      backgroundColor: "transparent",
      regionStyle: {
        initial: {
          fill: "rgba(255, 255, 255, 0.7)",
          "fill-opacity": 1,
          stroke: "rgba(0,0,0,.2)",
          "stroke-width": 1,
          "stroke-opacity": 1,
        },
      },
      series: {
        regions: [
          {
            values: visitorsData,
            scale: ["#ffffff", "#0154ad"],
            normalizeFunction: "polynomial",
          },
        ],
      },
      onRegionLabelShow: function (e, el, code) {
        if (typeof visitorsData[code] !== "undefined") {
          el.html(el.html() + ": " + visitorsData[code] + " new visitors");
        }
      },
    });

    return () => {
      window.$("#world-map").html("");
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="wrapper">
        <Header />
        <Sidebar />

        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <Title
                  items={[{ title: "Home", link: "#" }, { title: "Dashboard" }]}
                />
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-7">
                  <div className="card">
                    <div className="card-header border-0">
                      <h3 className="card-title">JORNADA EN CURSO</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="font-weight-light">
                            {nextFixtureWindow
                              ? "JORNADA " + nextFixtureWindow.weekNumber
                              : "NO HAY JORNADAS"}
                          </h5>
                          {nextFixtureWindow && (
                            <p>
                              Del{" "}
                              {moment(nextFixtureWindow.startDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              al{" "}
                              {moment(nextFixtureWindow.endDate).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      {nextFixtureWindow && (
                        <div className="row">
                          <div className="col-md-6">
                            <Widget
                              value={
                                allFixtures.filter(
                                  (f) =>
                                    f.week === nextFixtureWindow.weekNumber &&
                                    (f.status === "Finished" ||
                                      f.status === "Scheduled")
                                ).length +
                                " de " +
                                allFixtures.filter(
                                  (f) => f.week === nextFixtureWindow.weekNumber
                                ).length
                              }
                              icon="fas fa-calendar"
                              label={"Fechas cerradas"}
                              color={"bg-warning"}
                            />
                          </div>
                          <div className="col-md-6">
                            <Widget
                              value={
                                allFixtures.filter(
                                  (f) =>
                                    f.week === nextFixtureWindow.weekNumber &&
                                    f.status === "Finished"
                                ).length +
                                " de " +
                                allFixtures.filter(
                                  (f) => f.week === nextFixtureWindow.weekNumber
                                ).length
                              }
                              icon="fas fa-check"
                              label={"Enfrentamientos finalizados"}
                              color={"bg-success"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header border-0">
                      <h3 className="card-title">CLASIFICACIONES</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <select
                              className="custom-select"
                              onChange={handleGroupSelection}
                            >
                              {allGroups.map((g) => {
                                return <option value={g.id}>{g.name}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <Table
                            data={selectedGroupStanding}
                            fields={[
                              {
                                titleField: "Pos",
                                objectField: "position",
                              },
                              {
                                titleField: "Equipo",
                                objectField: "team.name",
                              },
                              {
                                titleField: "G",
                                objectField: "won",
                              },
                              {
                                titleField: "P",
                                objectField: "lost",
                              },
                              {
                                titleField: "E",
                                objectField: "drawn",
                              },

                              {
                                titleField: "Puntos",
                                objectField: "totalPoints",
                              },
                            ]}
                            actions={[
                              {
                                title: "Ver equipo",
                                ico: "fas fa-eye",
                                class: "success",
                                action: (obj) => {
                                  // redirect to team
                                  navigate("/equipo/" + obj.team.id);
                                },
                              },
                            ]}
                            options={{
                              paging: false,
                              ordering: false,
                              searching: false,
                              info: false,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div
                    className="card"
                    style={{
                      maxHeight: "820px",
                      overflow: "hidden",
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#7f2227 #ffffff",
                      WebkitOverflowScrolling: "touch",
                      msScrollbarArrowColor: "transparent",
                      scrollbarGutter: "0",
                    }}
                  >
                    <div className="card-header border-0">
                      <h3 className="card-title">CALENDARIO</h3>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              if (hasPreviousWeek) handlePreviousWeek();
                            }}
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                        </div>
                        <div className="col-8 text-center">
                          <h5>Jornada {selectedFixtureWindow.weekNumber}</h5>
                        </div>
                        <div className="col-2 text-right">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              if (hasNextWeek) handleNextWeek();
                            }}
                          >
                            <i className="fa fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {allFixtures
                            .filter(
                              (f) => f.week === selectedFixtureWindow.weekNumber
                            )
                            .map((f) => {
                              return <FixtureItem fixture={f} />;
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
        <Settings />
      </div>
    </>
  );
};

export default Dashboard;
