import { ApiUrlBase } from "../helpers/constants";

async function _me() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/accounts/me", requestOptions);
}

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/accounts", requestOptions);
}

async function _ban(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");
  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/accounts/" + id + "/ban", requestOptions);
}

async function _unban(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");
  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/accounts/" + id + "/ban", requestOptions);
}

export const AccountsService = {
  me: _me,
  getAll: _getAll,
  ban: _ban,
  unban: _unban,
};
