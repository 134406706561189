import { useEffect, useState } from "react";
import moment from "moment/moment";
import { NewsletterService } from "../../services/newsletterService";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Footer from "../../components/Layout/Footer";
import Loader from "../../components/Layout/Loader";
import Settings from "../../components/Layout/Settings";
import Table from "../../components/Common/Table";

const Newsletter = () => {
  const [newsletterSubscriptions, setNewsletterSubscriptions] = useState([]);
  const [newsletterSubscriptionsActive, setNewsletterSubscriptionsActive] =
    useState([]);
  const [newsletterSubscriptionsUnActive, setNewsletterSubscriptionsUnActive] =
    useState([]);

  const getAllNewsletterSubscriptions = async () => {
    const response = await NewsletterService.getAll();
    if (response.ok) {
      const result = await response.json();
      setNewsletterSubscriptions(result);
      const nlsActive = [];
      const nlsUnActive = [];
      {
        newsletterSubscriptions.map((subscription) => {
          if (subscription.unsubscriptionDate == null)
            nlsActive.push(subscription);
          else nlsUnActive.push(subscription);
        });
      }
      setNewsletterSubscriptionsActive(nlsActive);
      setNewsletterSubscriptionsUnActive(nlsUnActive);
    } else {
      return {
        success: false,
        message:
          "No se han podido obtener las suscripciones de la newsletter de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllNewsletterSubscriptions();
  }, []);

  useEffect(() => {
    if (newsletterSubscriptions.length > 0) initDataTables();
  }, [newsletterSubscriptions]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblSubscriptions")) {
      window.$("#tblSubscriptions").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2, 3],
            className: "text-center",
          },
        ],
      });
    }
  };

  const unsuscribe = async (id) => {
    const response = await NewsletterService.unsuscribe(id);
    if (response.ok) {
      getAllNewsletterSubscriptions();
    }
  };

  const downloadReport = async () => {
    const response = await NewsletterService.download();
    if (response.ok) {
      // Get file content as blob
      const result = await response.blob();

      // Create a link to download the file
      const url = window.URL.createObjectURL(result);
      const link = document.createElement("a");
      link.href = url;
      link.download = "newsletter-subscribers.csv";
      link.click();

      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {newsletterSubscriptions.length > 0 ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Web", url: "#" },
                      {
                        title: "Suscripciones a Newsletter",
                        url: "/newsletter",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Usuarios suscritos"
                      value={newsletterSubscriptions.length}
                      icon="fas fa-mail-bulk"
                    />
                  </div>
                  <div className="col-7"></div>
                  <div className="col-2 text-right">
                    <button
                      class="btn btn-app bg-info mt-5"
                      onClick={() => {
                        downloadReport();
                      }}
                    >
                      <i class="fas fa-download"></i> Descargar Base de Datos
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">
                          Usuarios suscritos Activos
                        </h3>
                      </div>
                      <div className="card-body">
                        <Table
                          data={newsletterSubscriptionsActive}
                          fields={[
                            {
                              objectField: "id",
                              titleField: "Id",
                            },
                            {
                              objectField: "email",
                              titleField: "Email",
                            },
                            {
                              objectField: "subscriptionDate",
                              titleField: "Fecha Suscripción",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">
                          Usuarios suscritos Inactivos
                        </h3>
                      </div>
                      <div className="card-body">
                        <Table
                          data={newsletterSubscriptionsUnActive}
                          fields={[
                            {
                              objectField: "id",
                              titleField: "Id",
                            },
                            {
                              objectField: "email",
                              titleField: "Email",
                            },
                            {
                              objectField: "subscriptionDate",
                              titleField: "Fecha suscripción",
                            },
                            {
                              objectField: "unsubscriptionDate",
                              titleField: "Fecha Baja",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};

export default Newsletter;
