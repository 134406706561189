const Alert = ({ title, body, canClose, type, icon }) => {
  return (
    <div class={"alert alert-" + type}>
      {canClose ? (
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
      ) : null}
      <h5>
        <i class={icon}></i> &nbsp;{title}
      </h5>
      {body}
    </div>
  );
};

export default Alert;
