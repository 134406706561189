import { ApiUrlBase } from "../helpers/constants";

async function _me() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/users/me", requestOptions);
}

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/users", requestOptions);
}

async function _getUsersWithoutTeam() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/users/unassigned", requestOptions);
}

async function _getUser(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/users/" + id, requestOptions);
}

async function _getUserCard(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/users/" + id + "/user-card", requestOptions);
}

async function _updateUser(
  id,
  name,
  surname,
  personalId,
  phoneNumber,
  addressName,
  addressNumber,
  addressOther,
  zipCode,
  cityId,
  provinceId,
  teamId
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      id: id,
      name: name,
      surname: surname,
      personalId: personalId,
      phoneNumber: phoneNumber,
      addressName: addressName,
      addressNumber: addressNumber,
      addressOther: addressOther,
      zipCode: zipCode,
      cityId: cityId,
      provinceId: provinceId,
      teamId: teamId,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/users", requestOptions);
}

export const UsersService = {
  me: _me,
  getAll: _getAll,
  getUser: _getUser,
  getUserCard: _getUserCard,
  updateUser: _updateUser,
  getUsersWithoutTeam: _getUsersWithoutTeam,
};
