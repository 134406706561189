import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/groups", requestOptions);
}

async function _get(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/groups/" + id, requestOptions);
}

async function _updateGroup(id, categoryId, divisionId, name, territoryId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      categoryId: categoryId,
      divisionId: divisionId,
      name: name,
      territoryId: territoryId,
    }),
  };
  console.log(requestOptions);
  return fetch(ApiUrlBase + "/groups/" + id, requestOptions);
}

async function _createGroup(categoryId, divisionId, name, territoryId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      categoryId: categoryId,
      divisionId: divisionId,
      name: name,
      territoryId: territoryId,
    }),
  };
  return fetch(ApiUrlBase + "/groups/", requestOptions);
}
async function _deleteGroup(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/groups/" + id, requestOptions);
}

async function _getGroupTeams(groupId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/groups/" + groupId + "/teams", requestOptions);
}

async function _addGroupTeam(groupId, teamId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({ id: teamId }),
  };

  console.log(ApiUrlBase + "/groups/" + groupId + "/teams");

  return fetch(ApiUrlBase + "/groups/" + groupId + "/teams", requestOptions);
}

async function _deleteGroupTeam(groupId, teamId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(
    ApiUrlBase + "/groups/" + groupId + "/teams/" + teamId,
    requestOptions
  );
}
async function _getGroupCalendar(groupId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/groups/" + groupId + "/calendar", requestOptions);
}

async function _getGroupStandings(groupId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(
    ApiUrlBase + "/groups/" + groupId + "/standings",
    requestOptions
  );
}

async function _deleteCalendar(groupId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/groups/" + groupId + "/calendar", requestOptions);
}

async function _createCalendar(groupId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/groups/" + groupId + "/calendar", requestOptions);
}

async function _swapFixture(fixtureId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(
    ApiUrlBase + "/fixtures/" + fixtureId + "/schedule/swap",
    requestOptions
  );
}

export const GroupService = {
  getAll: _getAll,
  get: _get,
  updateGroup: _updateGroup,
  createGroup: _createGroup,
  deleteGroup: _deleteGroup,
  getGroupTeams: _getGroupTeams,
  addGroupTeam: _addGroupTeam,
  deleteGroupTeam: _deleteGroupTeam,
  getGroupCalendar: _getGroupCalendar,
  getGroupStandings: _getGroupStandings,
  deleteCalendar: _deleteCalendar,
  createCalendar: _createCalendar,
  swapFixture: _swapFixture,
};
