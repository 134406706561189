import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Layout/Loader";
import Alert from "../../../components/Common/Alert";
import { DivisionsService } from "../../../services/divisionsService";

const DivisionsEdit = ({ division, newDivision }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(newDivision);
  const [isNewDivision, setIsNewDivision] = useState(newDivision);
  const switchEditRef = useRef(null);

  const [divisionId, setDivisionId] = useState(
    newDivision ? undefined : division.id
  );
  const [txtName, setTxtName] = useState(newDivision ? "" : division.name);
  const [txtDescription, setTxtDescription] = useState(
    newDivision ? "" : division.description
  );

  const validateName = () => {
    if (txtName != undefined) {
      if (txtName.length === 0) {
        return "El Nombre es obligatorio.";
      } else if (txtName.length > 512) {
        return "El Nombre debe tener menos de 512 caracteres.";
      } else {
        return null;
      }
    } else return "La pregunta es obligatoria.";
  };

  const validateDescription = () => {
    if (txtDescription != undefined) {
      if (txtDescription.length === 0) {
        return "La descripción es obligatoria.";
      } else if (txtDescription.length > 512) {
        return "La descripción debe tener menos de 512 caracteres.";
      } else {
        return null;
      }
    } else return "La descripción es obligatoria.";
  };

  const validateAll = () => {
    const formErrors = {
      name: validateName(),
      description: validateDescription(),
    };

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    if (Object.values(errors).every((x) => x === null)) {
      if (isNewDivision) {
        const createDivision = async () => {
          const response = await DivisionsService.createDivision(
            txtName,
            txtDescription
          );

          if (response.ok) {
            const createdDivision = await response.json();
            setDivisionId(createdDivision.id);
            setEdit(false);
            setIsNewDivision(false);
          } else {
            // show error message
          }
        };
        createDivision();
      } else {
        const updateDivision = async () => {
          const response = await DivisionsService.updateDivision(
            divisionId,
            txtName,
            txtDescription
          );

          if (response.ok) {
            switchEditRef.current.checked = false;
            setEdit(false);
            // show success message
          } else {
            // show error message
          }
        };
        updateDivision();
      }
    } else {
      console.log(errors);
    }
  };
  return (
    <div className="row">
      <div className="col-md-12">
        {isNewDivision ? null : (
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                value={edit}
                ref={switchEditRef}
                onChange={(e) => setEdit(e.target.checked)}
                class="custom-control-input"
                id="edit"
              />
              <label class="custom-control-label" for="edit">
                Activar el modo edición
              </label>
            </div>
          </div>
        )}
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="question">Nombre</label>
                  <input
                    type="text"
                    className={
                      (errors.name ? "is-invalid " : "") + "form-control"
                    }
                    id="name"
                    value={txtName}
                    onChange={(e) => setTxtName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.name}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="answer">Descripción</label>
                  <textarea
                    className={
                      (errors.description ? "is-invalid " : "") + "form-control"
                    }
                    rows={10}
                    id="description"
                    value={txtDescription}
                    onChange={(e) => setTxtDescription(e.target.value)}
                    disabled={!edit}
                  ></textarea>
                  <span className="invalid-feedback">{errors.description}</span>
                </div>
              </div>
            </div>
          </div>
          {edit ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default DivisionsEdit;
