import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../../logo.png";
import SimpleItem from "./SimpleItem";
import TreeItem from "./TreeItem/TreeItem";
import UserSession from "./UserSession";

const Sidebar = () => {
  // Initialize treeview plugin
  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview("init");

    return () => {
      window
        .$(document)
        .on("click", '[data-widget="treeview"] .nav-link', function (e) {
          e.stopImmediatePropagation();
        });
    };
  }, []);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/" className="brand-link bg-light">
        <img
          src={logo}
          alt="NCL Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: 0.8 }}
        />
        <span className="brand-text font-weight-light">AdminBox</span>
      </a>

      <div className="sidebar">
        <UserSession />

        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <SimpleItem
              name={"Dashboard"}
              faIcon={"fas fa-tachometer-alt"}
              active={true}
              link={"/"}
            />

            <TreeItem
              name={"Competición"}
              faIcon={"fas fa-trophy"}
              children={[
                {
                  name: "Deportistas",
                  faIcon: "fas fa-user",
                  link: "/deportistas",
                },
                {
                  name: "Grupos",
                  faIcon: "fas fa-object-group",
                  link: "/groups",
                },
                { name: "Equipos", faIcon: "fas fa-users", link: "/equipos" },
                { name: "Boxes", faIcon: "fas fa-dumbbell", link: "/boxes" },
                {
                  name: "Divisiones",
                  faIcon: "fas fa-layer-group",
                  link: "/divisions",
                },
                {
                  name: "Territorios",
                  faIcon: "fas fa-globe-europe",
                  link: "/territories",
                },
                {
                  name: "Categorías",
                  faIcon: "fas fa-list-ol",
                  link: "/categories",
                },
                {
                  name: "Ejercicios",
                  faIcon: "fas fa-dumbbell",
                  link: "/exercises",
                },
                {
                  name: "Eventos",
                  faIcon: "fas fa-calendar-alt",
                  link: "/events",
                },
                {
                  name: "Wods",
                  faIcon: "fas fa-walking",
                  link: "/wods",
                },
              ]}
            />

            <TreeItem
              name={"App"}
              faIcon={"fas fa-mobile-alt"}
              children={[
                { name: "Usuarios", faIcon: "fas fa-user", link: "/usuarios" },
                {
                  name: "Estadísticas",
                  faIcon: "fas fa-chart-bar",
                  link: "/statistics",
                },
                {
                  name: "Comunicaciones",
                  faIcon: "fas fa-bullhorn",
                  link: "/comunications",
                },
              ]}
            />

            <TreeItem
              name={"Web"}
              faIcon={"fas fa-globe"}
              children={[
                {
                  name: "Solicitud de altas",
                  faIcon: "fas fa-user-plus",
                  link: "/subscriptionRequest",
                },

                {
                  name: "FAQs",
                  faIcon: "fas fa-question",
                  link: "/faqs",
                },

                {
                  name: "Newsletter",
                  faIcon: "fas fa-mail-bulk",
                  link: "/newsletter",
                },
              ]}
            />
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
