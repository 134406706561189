import { Link } from "react-router-dom";

const Widget = ({ label, value, link, icon, color }) => {
  return (
    <div className={(color == null ? "bg-info" : color) + " small-box"}>
      <div className="inner">
        <h3>{value == null ? "N/A" : value}</h3>

        <p>{label == null ? "Etiqueta" : label}</p>
      </div>
      <div className="icon">
        <i className={icon == null ? "ion ion-pie-graph" : icon}></i>
      </div>
      {link == null ? null : (
        <Link to={link} className="small-box-footer">
          Más <i className="fas fa-arrow-circle-right"></i>
        </Link>
      )}
    </div>
  );
};

export default Widget;
