import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/categories", requestOptions);
}

async function _updateCategory(id, name, description) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/categories/" + id, requestOptions);
}

async function _createCategory(name, description) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/categories/", requestOptions);
}
async function _deleteCategory(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/categories/" + id, requestOptions);
}

export const CategoriesService = {
  getAll: _getAll,
  updateCategory: _updateCategory,
  createCategory: _createCategory,
  deleteCategory: _deleteCategory,
};
