import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/boxes", requestOptions);
}

async function _updateBox(
  id,
  name,
  addressName,
  addressNumber,
  addressOther,
  zipCode,
  cityId,
  provinceId,
  email,
  phone,
  verified
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      name: name,
      addressName: addressName,
      addressNumber: addressNumber,
      addressOther: addressOther,
      zipCode: zipCode,
      cityId: cityId,
      provinceId: provinceId,
      email: email,
      phone: phone,
      verified: verified,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/boxes/" + id, requestOptions);
}

async function _createBox(
  name,
  addressName,
  addressNumber,
  addressOther,
  zipCode,
  cityId,
  provinceId,
  email,
  phone,
  verified
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      name: name,
      addressName: addressName,
      addressNumber: addressNumber,
      addressOther: addressOther,
      zipCode: zipCode,
      cityId: cityId,
      provinceId: provinceId,
      email: email,
      phone: phone,
      verified: verified,
    }),
  };
  return fetch(ApiUrlBase + "/boxes", requestOptions);
}

async function _deleteBox(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/boxes/" + id, requestOptions);
}

async function _verifyBox(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/boxes/" + id + "/verify", requestOptions);
}

async function _removeVerifyBox(id) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };
  return fetch(ApiUrlBase + "/boxes/" + id + "/verify", requestOptions);
}

export const BoxesService = {
  getAll: _getAll,
  updateBox: _updateBox,
  createBox: _createBox,
  deleteBox: _deleteBox,
  verifyBox: _verifyBox,
  removeVerifyBox: _removeVerifyBox,
};
