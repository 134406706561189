import { useEffect, useState } from "react";

const Modal = ({
  title,
  body,
  show,
  acceptButton,
  cancelButton,
  handleClose,
}) => {
  var id = "mdl-" + Math.random().toString(36).substring(7);

  useEffect(() => {
    if (show) {
      window.$("#" + id).modal("show");
    } else {
      window.$("#" + id).modal("hide");
    }

    window.$("#" + id).off("hidden.bs.modal");
    window.$("#" + id).on("hidden.bs.modal", function (e) {
      handleClose();
    });
  });

  return (
    <div className="modal fade" id={id}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{body}</div>
          {cancelButton || acceptButton ? (
            <div className="modal-footer justify-content-between">
              {cancelButton ? (
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  {cancelButton.text}
                </button>
              ) : null}
              {acceptButton ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={acceptButton.action}
                >
                  {acceptButton.text}
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Modal;
