import { useEffect, useRef, useState } from "react";
import Alert from "../../../components/Common/Alert";
import { MiscService } from "../../../services/miscService";
import { BoxesService } from "../../../services/boxesService";

const BoxEdit = ({ box, newBox }) => {
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const [boxId, setBoxId] = useState(newBox ? undefined : box?.id);
  const [name, setName] = useState(newBox ? "" : box?.name);
  const [email, setEmail] = useState(newBox ? "" : box?.email);
  const [phone, setPhone] = useState(newBox ? "" : box?.phone);
  const [addressName, setAddressName] = useState(
    newBox ? "" : box?.addressName
  );
  const [addressNumber, setAddressNumber] = useState(
    newBox ? "" : box?.addressNumber
  );
  const [addressOther, setAddressOther] = useState(
    newBox ? "" : box?.addressOther
  );
  const [zipCode, setZipCode] = useState(newBox ? "" : box?.zipCode);
  const [provinceId, setProvinceId] = useState(newBox ? "" : box?.provinceId);
  const [cityId, setCityId] = useState(newBox ? "" : box?.cityId);

  const [verified, setVerified] = useState(newBox ? false : box?.verified);

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(newBox);
  const [isNewBox, setIsNewBox] = useState(newBox);
  const switchEditRef = useRef(null);

  // Load provinces, cities and teams
  useEffect(() => {
    const loadProvinces = async () => {
      const response = await MiscService.getAllProvinces();
      const provinces = await response.json();
      setProvinces(provinces);
    };

    const loadCities = async () => {
      const response = await MiscService.getAllCities();
      const cities = await response.json();
      setCities(cities);
    };

    const loaders = [loadProvinces(), loadCities()];
    Promise.all(loaders).then(() => setLoading(false));
  }, []);

  // Reload cities when provinceId changes
  useEffect(() => {
    const loadCities = async () => {
      const response = await MiscService.getCitiesByProvinceId(provinceId);
      const cities = await response.json();
      setCities(cities);

      // If the cityId is not in the new cities list, set it to 0
      if (!cities.some((city) => city.id === cityId)) {
        setCityId(0);
      }
    };

    if (provinceId) {
      loadCities();
    }
  }, [provinceId]);

  // validate name
  const validateName = () => {
    if (name.length === 0) {
      return "El nombre es obligatorio.";
    } else if (name.length < 3) {
      return "El nombre debe tener al menos 3 caracteres.";
    } else if (name.length > 50) {
      return "El nombre debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate email
  const validateEmail = () => {
    if (email.length === 0) {
      return "El email es obligatorio.";
    } else if (email.length < 3) {
      return "El email debe tener al menos 3 caracteres.";
    } else if (email.length > 512) {
      return "El email debe tener menos de 512 caracteres.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "El email debe ser un email válido.";
    } else {
      return null;
    }
  };

  // validate addressName
  const validateAddressName = () => {
    if (addressName.length === 0) {
      return "La calle es obligatoria.";
    } else if (addressName.length < 3) {
      return "La calle debe tener al menos 3 caracteres.";
    } else if (addressName.length > 50) {
      return "La calle debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate addressNumber
  const validateAddressNumber = () => {
    if (addressNumber.length === 0) {
      return "El número es obligatorio.";
    } else if (addressNumber.length < 1) {
      return "El número debe tener al menos 1 caracteres.";
    } else if (addressNumber.length > 10) {
      return "El número debe tener menos de 10 caracteres.";
    } else {
      return null;
    }
  };

  // validate addressOther
  const validateAddressOther = () => {
    if (addressOther.length > 50) {
      return "El resto de la dirección debe tener menos de 50 caracteres.";
    } else {
      return null;
    }
  };

  // validate provinceId
  const validateProvinceId = () => {
    if (provinceId === 0 || provinceId === "0") {
      return "La provincia es obligatoria.";
    } else {
      return null;
    }
  };

  // validate cityId
  const validateCityId = () => {
    if (cityId === 0 || cityId === "0") {
      return "La ciudad es obligatoria.";
    } else {
      return null;
    }
  };

  // validate zipCode
  const validateZipCode = () => {
    if (zipCode.length === 0) {
      return "El código postal es obligatorio.";
    } else if (zipCode.length !== 5) {
      return "El código postal debe tener 5 caracteres.";
    } else {
      return null;
    }
  };

  // validate phone number. It must be a valid spanish phone number
  const validatePhone = () => {
    if (phone.length === 0) {
      return "El teléfono es obligatorio.";
    } else if (phone.length !== 9) {
      return "El teléfono debe tener 9 caracteres.";
    } else if (!/^[6|7|9][0-9]{8}$/.test(phone)) {
      return "El teléfono debe ser un número de teléfono español válido.";
    } else {
      return null;
    }
  };

  // validate all
  const validateAll = () => {
    const formErrors = {
      name: validateName(),
      email: validateEmail(),
      addressName: validateAddressName(),
      addressNumber: validateAddressNumber(),
      addressOther: validateAddressOther(),
      provinceId: validateProvinceId(),
      cityId: validateCityId(),
      zipCode: validateZipCode(),
      phone: validatePhone(),
    };

    return formErrors;
  };

  const verify = async (id) => {
    const response = await BoxesService.verifyBox(id);
    if (response.ok) {
      setVerified(true);
    }
  };

  const removeVerify = async (id) => {
    const response = await BoxesService.removeVerifyBox(id);
    if (response.ok) {
      setVerified(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    if (Object.values(errors).every((x) => x === null)) {
      if (isNewBox) {
        const updateBox = async () => {
          const response = await BoxesService.createBox(
            name,
            addressName,
            addressNumber,
            addressOther,
            zipCode,
            cityId,
            provinceId,
            email,
            phone,
            verified
          );

          if (response.ok) {
            setIsNewBox(false);
            setEdit(false);
            const newBox = await response.json();
            setBoxId(newBox.id);
            // show success message
          } else {
            // show error message
          }
        };

        updateBox();
      } else {
        const updateBox = async () => {
          const response = await BoxesService.updateBox(
            boxId,
            name,
            addressName,
            addressNumber,
            addressOther,
            zipCode,
            cityId,
            provinceId,
            email,
            phone,
            verified
          );

          if (response.ok) {
            switchEditRef.current.checked = false;
            setEdit(false);
            // show success message
          } else {
            // show error message
          }
        };

        updateBox();
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      {verified ? (
        <Alert
          title={"Box verificado"}
          canClose={false}
          type={"success"}
          icon={"fas fa-star"}
        />
      ) : (
        <Alert
          title={"Box no verificado"}
          canClose={false}
          type={"warning"}
          icon={"far fa-star"}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div class="form-group">
            {isNewBox ? null : (
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  value={edit}
                  ref={switchEditRef}
                  onChange={(e) => setEdit(e.target.checked)}
                  class="custom-control-input"
                  id="edit"
                />
                <label class="custom-control-label" for="edit">
                  Activar el modo edición
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">
                    Nombre <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.name ? "is-invalid " : "") + "form-control"
                    }
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.name}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="phone">
                    Teléfono <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.phone ? "is-invalid " : "") + "form-control"
                    }
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.phone}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="email">
                    Email <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.email ? "is-invalid " : "") + "form-control"
                    }
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.email}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="addressName">
                    Dirección (Tipo y nombre de vía){" "}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressName ? "is-invalid " : "") + "form-control"
                    }
                    id="addressName"
                    value={addressName}
                    onChange={(e) => setAddressName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.addressName}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="addressNumber">
                    Número <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressNumber ? "is-invalid " : "") +
                      "form-control"
                    }
                    id="addressNumber"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">
                    {errors.addressNumber}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="addressOther">
                    Escalera, Piso, Puerta...
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressOther ? "is-invalid " : "") +
                      "form-control"
                    }
                    id="addressOther"
                    value={addressOther}
                    onChange={(e) => setAddressOther(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">
                    {errors.addressOther}
                  </span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="zipCode">
                    Código postal <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      (errors.addressOther ? "is-invalid " : "") +
                      "form-control"
                    }
                    id="zipCode"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.zipCode}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="provinceId">
                    Provincia <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.provinceId ? "is-invalid " : "") + "form-control"
                    }
                    id="provinceId"
                    value={provinceId}
                    onChange={(e) => setProvinceId(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una provincia</option>
                    {provinces.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.provinceId}</span>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="cityId">
                    Ciudad <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.cityId ? "is-invalid " : "") + "form-control"
                    }
                    id="cityId"
                    value={cityId}
                    onChange={(e) => setCityId(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una ciudad --</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.cityId}</span>
                </div>
              </div>
            </div>

            {edit ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary mr-3"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
                {verified ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeVerify(box.id)}
                  >
                    Quitar Verificado
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => verify(box.id)}
                  >
                    Verificar
                  </button>
                )}
              </>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default BoxEdit;
