import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Layout/Loader";
import Alert from "../../../components/Common/Alert";
import { WodService } from "../../../services/wodService";
import { ExercisesService } from "../../../services/exercisesService";
import Table from "../../../components/Common/Table";

const WodEdit = ({ wod, newWod }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(newWod);
  const [isNewWod, setIsNewWod] = useState(newWod);
  const switchEditRef = useRef(null);

  const [wodId, setWodId] = useState(newWod ? undefined : wod.id);
  const [txtDescription, setTxtDescription] = useState(
    newWod ? "" : wod.description
  );
  const [txtFullDescription, setTxtFullDescription] = useState(
    newWod ? "" : wod.fullDescription
  );

  const [qualifyType, setQualifyType] = useState(
    newWod ? "0" : wod.qualifyType
  );

  const [qualifyType2, setQualifyType2] = useState(
    newWod ? "0" : wod.qualifyType2 ? wod.qualifyType2 : "0"
  );
  const [partner, setPartner] = useState(newWod ? "0" : wod.partner);
  const [gender1, setGender1] = useState(newWod ? "0" : wod.gender1);
  const [showGender2, setShowGender2] = useState(
    wod.partner == "2" ? true : false
  );
  const [gender2, setGender2] = useState(newWod ? "0" : wod.gender2);

  //Exercises
  const [exercisesInWod, setExercisesInWod] = useState(wod.exercises);
  const [showExercisesTable, setShowExercisesTable] = useState(false);
  const [wodExercisesNotAssigned, setWodExercisesNotAssigned] = useState([]);

  const addExerciseToWod = async (exercise) => {
    console.log("Ejercicio: ", exercise);
    // assign exercise to wod
    const response = await WodService.addExerciseToWod(wodId, exercise.id);
    const data = await response.json();
    // add exercise to wod.exercises
    setExercisesInWod([...exercisesInWod, exercise]);
    // remove exercise from wodExerciseNotAssigned
    const index = wodExercisesNotAssigned.indexOf(exercise);
    const remainingExercises = wodExercisesNotAssigned.splice(index, 1);
    setWodExercisesNotAssigned(remainingExercises);
    setShowExercisesTable(false);
  };

  const deleteExerciseToWod = async (exercise) => {
    // Unassign exercise to wod
    const response = await WodService.deleteExerciseToWod(wodId, exercise.id);
    const data = await response.json();
    // remove exercise to wod.exercises
    const removeExerciseFromWod = (exerciseToRemove) => {
      const updatedExercisesInWod = exercisesInWod.filter(
        (exercise) => exercise.id !== exerciseToRemove.id
      );
      setExercisesInWod(updatedExercisesInWod);
    };
    removeExerciseFromWod(exercise);
    // remove exercise from wodExerciseNotAssigned
    const remainingExercises = wodExercisesNotAssigned.push(exercise);
    setWodExercisesNotAssigned(remainingExercises);
    setShowExercisesTable(false);
  };

  // fill not assigned exercises
  useEffect(() => {
    const fillAvailableExercises = async () => {
      try {
        const allExercisesRequest = await ExercisesService.getAll();
        const allExercises = await allExercisesRequest.json();

        const notSelected = allExercises.filter((exercise) => {
          return !exercisesInWod.some(
            (selectedExercise) => selectedExercise.id === exercise.id
          );
        });

        setWodExercisesNotAssigned(notSelected);
      } catch (error) {
        console.error("Error fetching and filtering exercises:", error);
      }
    };
    fillAvailableExercises();
  }, [exercisesInWod]);

  useEffect(() => {
    if (partner == 1) {
      // Individual
      setShowGender2(false);
      setGender2(0);
    }
    // Parejas
    else {
      setShowGender2(true);
      if (gender2 == 0) setGender2(1);
    }
  }, [partner]);

  const validateDescription = () => {
    if (txtDescription != undefined) {
      if (txtDescription.length === 0) {
        return "El nombre es obligatorio.";
      } else if (txtDescription.length > 256) {
        return "El nombre debe tener menos de 256 caracteres.";
      } else {
        return null;
      }
    } else return "El nombre es obligatorio.";
  };

  const validateFullDescription = () => {
    if (txtFullDescription != undefined) {
      if (txtFullDescription.length === 0) {
        return "La descripción es obligatoria.";
      } else if (txtFullDescription.length > 512) {
        return "La descripcion debe tener menos de 512 caracteres.";
      } else {
        return null;
      }
    } else return "La descripción es obligatoria.";
  };

  const validateQualifyType = () => {
    if (qualifyType != undefined) {
      if (qualifyType == "0") return "El Tipo de Clasificación es obligatoria";
      else {
        return null;
      }
    } else return "El Tipo de Clasificación es obligatoria";
  };

  const validatePartner = () => {
    if (partner != undefined) {
      if (partner == "0") return "El Campo Individual/Parejas es obligatorio";
      else {
        return null;
      }
    } else return "El Campo Individual/Parejas es obligatorio";
  };

  const validateGender1 = () => {
    if (gender1 != undefined) {
      if (gender1 == "0") return "El Sexo es obligatorio";
      else {
        return null;
      }
    } else return "El Sexo es obligatorio";
  };

  const validateGender2 = () => {
    if (partner == "2") {
      if (gender2 != undefined) {
        if (gender2 == "0") return "El Sexo es obligatorio";
        else {
          return null;
        }
      } else return "El Sexo es obligatorio";
    } else return null;
  };

  const validateAll = () => {
    const formErrors = {
      description: validateDescription(),
      fulldescription: validateFullDescription(),
      qualifyType: validateQualifyType(),
      partner: validatePartner(),
      gender1: validateGender1(),
      gender2: validateGender2(),
    };

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    if (Object.values(errors).every((x) => x === null)) {
      if (isNewWod) {
        const createWod = async () => {
          const response = await WodService.createWod(
            txtDescription,
            txtFullDescription,
            qualifyType,
            qualifyType2 === undefined ||
              qualifyType2 === null ||
              qualifyType2 === "0"
              ? null
              : qualifyType2,
            partner,
            gender1,
            gender2 === 0 ? null : gender2
          );

          if (response.ok) {
            const createdWod = await response.json();
            setWodId(createdWod.id);
            setEdit(false);
            setIsNewWod(false);
          } else {
            // show error message
          }
        };
        createWod();
      } else {
        const updateWod = async () => {
          const response = await WodService.updateWod(
            wodId,
            txtDescription,
            txtFullDescription,
            qualifyType,
            qualifyType2 === undefined ||
              qualifyType2 === null ||
              qualifyType2 === "0"
              ? null
              : qualifyType2,
            partner,
            gender1,
            gender2 === 0 ? null : gender2
          );

          if (response.ok) {
            switchEditRef.current.checked = false;
            setEdit(false);
            // show success message
          } else {
            // show error message
          }
        };
        updateWod();
      }
    } else {
      console.log(errors);
    }
  };
  if (showExercisesTable) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Añadir Ejercicio a Wod</h3>
                </div>
                <div className="card-body">
                  <Table
                    data={wodExercisesNotAssigned}
                    fields={[{ objectField: "name", titleField: "Nombre" }]}
                    actions={[
                      {
                        title: "Seleccionar",
                        ico: "fas fa-solid fa-check",
                        class: "success",

                        action: (obj) => {
                          addExerciseToWod(obj);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowExercisesTable(false)}
              >
                Volver
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {isNewWod ? null : (
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                value={edit}
                ref={switchEditRef}
                onChange={(e) => setEdit(e.target.checked)}
                class="custom-control-input"
                id="edit"
              />
              <label class="custom-control-label" for="edit">
                Activar el modo edición
              </label>
            </div>
          </div>
        )}
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="question">Nombre</label>
                  <input
                    type="text"
                    className={
                      (errors.description ? "is-invalid " : "") + "form-control"
                    }
                    id="description"
                    value={txtDescription}
                    onChange={(e) => setTxtDescription(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.description}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="answer">Descripción</label>
                  <textarea
                    className={
                      (errors.fulldescription ? "is-invalid " : "") +
                      "form-control"
                    }
                    rows={10}
                    id="description"
                    value={txtFullDescription}
                    onChange={(e) => setTxtFullDescription(e.target.value)}
                    disabled={!edit}
                  ></textarea>
                  <span className="invalid-feedback">
                    {errors.fulldescription}
                  </span>
                </div>
                <div className="form-group">
                  <label htmlFor="qualifyType">
                    Tipo de clasificación<span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.qualifyType ? "is-invalid " : "") + "form-control"
                    }
                    id="qualifyType"
                    value={qualifyType}
                    onChange={(e) => setQualifyType(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una opción --</option>

                    <option key="1" value="1">
                      TIME
                    </option>
                    <option key="2" value="2">
                      AMRAP
                    </option>
                    <option key="3" value="3">
                      RM
                    </option>
                  </select>
                  <span className="invalid-feedback">{errors.qualifyType}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="qualifyType">Tipo de clasificación 2</label>
                  <select
                    className="form-control"
                    id="qualifyType2"
                    value={qualifyType2}
                    onChange={(e) => setQualifyType2(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una opción --</option>

                    <option key="1" value="1">
                      TIME
                    </option>
                    <option key="2" value="2">
                      AMRAP
                    </option>
                    <option key="3" value="3">
                      RM
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="partner">
                    Individual / Parejas <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.partner ? "is-invalid " : "") + "form-control"
                    }
                    id="partner"
                    value={partner}
                    onChange={(e) => setPartner(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una opción</option>

                    <option key="1" value="1">
                      Individual
                    </option>
                    <option key="2" value="2">
                      Parejas
                    </option>
                  </select>
                  <span className="invalid-feedback">{errors.partner}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="gender1">
                    Sexo <span class="text-danger">*</span>
                  </label>
                  <select
                    className={
                      (errors.gender1 ? "is-invalid " : "") + "form-control"
                    }
                    id="gender1"
                    value={gender1}
                    onChange={(e) => setGender1(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="0">-- Selecciona una opción</option>

                    <option key="1" value="1">
                      Hombre
                    </option>
                    <option key="2" value="2">
                      Mujer
                    </option>
                  </select>
                  <span className="invalid-feedback">{errors.gender1}</span>
                </div>
                {!showGender2 ? null : (
                  <div className="form-group">
                    <label htmlFor="gender2">
                      Sexo 2 <span class="text-danger">*</span>
                    </label>
                    <select
                      className={
                        (errors.gender2 ? "is-invalid " : "") + "form-control"
                      }
                      id="gender2"
                      value={gender2}
                      onChange={(e) => setGender2(e.target.value)}
                      disabled={!edit}
                    >
                      <option value="0">-- Selecciona una opción</option>

                      <option key="1" value="1">
                        Hombre
                      </option>
                      <option key="2" value="2">
                        Mujer
                      </option>
                    </select>
                    <span className="invalid-feedback">{errors.gender2}</span>
                  </div>
                )}
                {isNewWod ? null : (
                  <div class="row">
                    <div class="col-12">
                      <div class="card card-info">
                        <div class="card-header">
                          <span>Ejercicios</span>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm float-right"
                            onClick={() => setShowExercisesTable(true)}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>

                        <div class="card-body">
                          {exercisesInWod.length > 0
                            ? exercisesInWod.map((exercise) => (
                                <>
                                  <p>
                                    {exercise.name}
                                    <button
                                      type="button"
                                      class="btn btn-danger btn-sm float-right"
                                      onClick={() =>
                                        deleteExerciseToWod(exercise)
                                      }
                                    >
                                      <i className="fas fa-minus"></i>
                                    </button>
                                  </p>
                                </>
                              ))
                            : null}
                        </div>
                        {edit ? null : <div class="overlay"></div>}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {edit ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default WodEdit;
