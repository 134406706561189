import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/fixtures", requestOptions);
}

async function _getAllFixtureWindows() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/fixtures/windows", requestOptions);
}

async function _getFixtureById(fixtureId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/fixtures/" + fixtureId, requestOptions);
}

async function _getFixtureSchedule(fixtureId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(
    ApiUrlBase + "/fixtures/" + fixtureId + "/schedule",
    requestOptions
  );
}

async function _rescheduleFixture(fixtureId, newDate, newStatus) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({ date: newDate, fixtureState: newStatus }),
  };

  return fetch(
    ApiUrlBase + "/fixtures/" + fixtureId + "/schedule",
    requestOptions
  );
}

async function _setFixtureResult(fixtureId, homeScore, awayScore) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({ homeScore: homeScore, awayScore: awayScore }),
  };

  return fetch(
    ApiUrlBase + "/fixtures/" + fixtureId + "/score",
    requestOptions
  );
}

async function _setMatchScore(
  fixtureId,
  matchId,
  homeScore,
  awayScore,
  homeScore2,
  awayScore2
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      matchId: matchId,
      homeScore: homeScore,
      awayScore: awayScore,
      homeScore2: homeScore2,
      awayScore2: awayScore2,
    }),
  };

  return fetch(
    ApiUrlBase + "/fixtures/" + fixtureId + "/set-score",
    requestOptions
  );
}

async function _refreshScore(fixtureId) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(
    ApiUrlBase + "/fixtures/" + fixtureId + "/refresh-score",
    requestOptions
  );
}

export const FixturesService = {
  getAll: _getAll,
  getFixtureById: _getFixtureById,
  getFixtureSchedule: _getFixtureSchedule,
  getAllFixtureWindows: _getAllFixtureWindows,
  rescheduleFixture: _rescheduleFixture,
  setFixtureResult: _setFixtureResult,
  setMatchScore: _setMatchScore,
  refreshScore: _refreshScore,
};
