import { useNavigate } from "react-router-dom";
import Alert from "../Alert";
import { UrlBase } from "../../../helpers/constants";
import FixtureUserDetails from "../FixtureUserDetails";

const FixtureTeamDetails = ({ fixture, isHome }) => {
  const navigate = useNavigate();
  const team = isHome ? fixture.homeTeam : fixture.awayTeam;
  const score = isHome ? fixture.homeScore : fixture.awayScore;
  const lineup = fixture.matches
    .map((match) => {
      var matchPlayers = [];
      if (isHome) {
        //return array of homeUser1 and homeUser2 (if any)
        if (match.homeUser1) {
          matchPlayers.push({ user: match.homeUser1, wod: match.wod });
        }
        if (match.homeUser2) {
          matchPlayers.push({ user: match.homeUser2, wod: match.wod });
        }
      } else {
        //return array of awayUser1 and awayUser2 (if any)
        if (match.awayUser1) {
          matchPlayers.push({ user: match.awayUser1, wod: match.wod });
        }
        if (match.awayUser2) {
          matchPlayers.push({ user: match.awayUser2, wod: match.wod });
        }
      }
      return matchPlayers;
    })
    .flat();

  return (
    <div className="card">
      <div className="card-header border-0">
        {isHome ? (
          <div className="row">
            <div className="col-10">
              <h1 className="font-weight-light">{team.name}</h1>
            </div>
            <div className="col-2">
              <h1 className="font-weight-light text-center">{score}</h1>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-2">
              <h1 className="font-weight-light text-center">{score}</h1>
            </div>
            <div className="col-10">
              <h1 className="font-weight-light text-right">{team.name}</h1>
            </div>
          </div>
        )}
      </div>
      <div className="card-body">
        <h5 className="font-weight-light">ALINEACIÓN</h5>
        <div className="row">
          <div className="col">
            {lineup.length > 0 ? (
              lineup.map(({ user, wod }) => (
                <FixtureUserDetails user={user} wod={wod} />
              ))
            ) : (
              <Alert
                title={"Info"}
                body={`No hay alineación para el equipo ${team.name}`}
                type="info"
                icon="fas fa-info-circle"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixtureTeamDetails;
