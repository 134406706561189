import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Title from "../../components/Common/Title/Title";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Loader from "../../components/Layout/Loader";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import { UsersService } from "../../services/usersService";
import { UrlBase } from "../../helpers/constants";
import { ActionsService } from "../../services/actionsService";
import Timeline from "../../components/Common/Timeline";
import moment from "moment";

const UserView = () => {
  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const { userId } = useParams();
  const [user, setUser] = useState(null);

  const [userCard, setUserCard] = useState(null);

  const [userActions, setUserActions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      const response = await UsersService.getUser(userId);
      const data = await response.json();
      setUser(data);
      console.log(data);
    };

    const getUserCard = async () => {
      const response = await UsersService.getUserCard(userId);
      const data = await response.json();
      setUserCard(data);
    };

    const getUserActions = async () => {
      const response = await ActionsService.getByUser(userId);
      const data = await response.json();
      setUserActions(data);
    };

    // promise all get user and get user actions
    Promise.all([getUser(), getUserCard(), getUserActions()]).then(() => {
      setIsLoading(false);
    });
  }, [userId]);

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Atletas", url: "/deportistas" },
                      { title: user.name + " " + user.surname, url: "#" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">Datos personales</h3>
                      </div>
                      <div className="card-body box-profile">
                        <div className="text-center">
                          <img
                            className="profile-user-img img-fluid img-circle"
                            src={
                              user.profilePic
                                ? `${UrlBase}/profile-pics/${user.profilePic}`
                                : "/dist/img/user0-128x128.png"
                            }
                            alt="User profile picture"
                          />
                        </div>
                        <h3 className="profile-username text-center">
                          {user.name} {user.surname}
                        </h3>
                        <p className="text-muted text-center">
                          {user.team ? user.team.name : "Sin equipo"}
                        </p>
                        <ul className="list-group list-group-unbordered mb-3">
                          <li className="list-group-item">
                            <b>GÉNERO</b>{" "}
                            <a className="float-right">
                              {user.gender === null
                                ? "-"
                                : user.gender === 1
                                ? "Hombre"
                                : "Mujer"}
                            </a>
                          </li>
                          <li className="list-group-item">
                            <b>NIF/NIE</b>{" "}
                            <a className="float-right">{user.personalId}</a>
                          </li>
                          <li className="list-group-item">
                            <b>DIRECCION</b>{" "}
                            <a className="float-right">
                              {user.addressName} {user.addressNumber}{" "}
                              {user.addressOther}
                            </a>
                          </li>
                          <li className="list-group-item">
                            <b>CIUDAD</b>{" "}
                            <a className="float-right">
                              {user.city?.name} - {user.zipCode}
                            </a>
                          </li>
                          <li className="list-group-item">
                            <b>PROVINCIA</b>{" "}
                            <a className="float-right">{user.province?.name}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">Contacto</h3>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                          <p className="text-danger text-xl">
                            <i className="fas fa-envelope"></i>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">
                              {user.account.email}
                            </span>
                            <span className="text-muted">EMAIL</span>
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                          <p className="text-primary text-xl">
                            <i className="fas fa-phone"></i>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">
                              {user.phoneNumber}
                            </span>
                            <span className="text-muted">TELÉFONO</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">Cuenta</h3>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                          <p
                            className={
                              user.account.active
                                ? "text-success text-xl"
                                : "text-danger text-xl"
                            }
                          >
                            <i className="fas fa-power-off"></i>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">
                              {user.account.active ? "ACTIVA" : "INACTIVA"}
                            </span>
                            <span className="text-muted">ESTADO</span>
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                          <p className="text-info text-xl">
                            <i className="far fa-calendar-alt"></i>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">
                              {moment(
                                new Date(user.account.signupDate + "z")
                              ).format("DD/MM/YYYY HH:mm")}
                            </span>
                            <span className="text-muted">
                              FECHA DE REGISTRO
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">CROMO</h3>
                      </div>
                      <div className="card-body">
                        {userCard ? (
                          <>
                            <div className="row">
                              <div className="col-lg-6">
                                <img
                                  class="img-fluid pad"
                                  src={
                                    userCard.cardImage
                                      ? `${UrlBase}/profile-pics/${userCard.cardImage}`
                                      : user.gender === null ||
                                        user.gender === 1
                                      ? "/dist/img/card-man.png"
                                      : "/dist/img/card-woman.png"
                                  }
                                  alt="Photo"
                                />
                              </div>
                              <div className="col-lg-6">
                                <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                  <p className="text-primary text-xl">
                                    <i className="far fa-calendar-alt"></i>
                                  </p>
                                  <p className="d-flex flex-column text-right">
                                    <span className="font-weight-bold">
                                      {user.birthDate}
                                    </span>
                                    <span className="text-muted">
                                      FECHA DE NACIMIENTO
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                  <p className="text-primary text-xl">
                                    <i className="fas fa-arrows-alt-v"></i>
                                  </p>
                                  <p className="d-flex flex-column text-right">
                                    <span className="font-weight-bold">
                                      {user.height}
                                    </span>
                                    <span className="text-muted">ALTURA</span>
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                  <p className="text-primary text-xl">
                                    <i className="fas fa-weight-hanging"></i>
                                  </p>
                                  <p className="d-flex flex-column text-right">
                                    <span className="font-weight-bold">
                                      {user.weight}
                                    </span>
                                    <span className="text-muted">PESO</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h4 className="font-weight-light mb-2">MARCAS</h4>
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="d-flex flex-column text-right">
                                  <span className="font-weight-bold">
                                    {userCard.deadlift} KG
                                  </span>
                                  <span className="text-muted">DEADLIFT</span>
                                </p>
                                <div class="progress">
                                  <div
                                    class="progress-bar bg-danger"
                                    role="progressbar"
                                    aria-valuenow={userCard.deadliftPercentile}
                                    aria-valuemin="0"
                                    aria-valuemax="10"
                                    style={{
                                      width:
                                        userCard.deadliftPercentile * 10 + "%",
                                    }}
                                  >
                                    <span class="sr-only">
                                      {userCard.deadlift} KG
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <p className="d-flex flex-column text-right">
                                  <span className="font-weight-bold">
                                    {userCard.backSquat} KG
                                  </span>
                                  <span className="text-muted">BACKSQUAT</span>
                                </p>
                                <div class="progress">
                                  <div
                                    class="progress-bar bg-danger"
                                    role="progressbar"
                                    aria-valuenow={userCard.backSquatPercentile}
                                    aria-valuemin="0"
                                    aria-valuemax="10"
                                    style={{
                                      width:
                                        userCard.backSquatPercentile * 10 + "%",
                                    }}
                                  >
                                    <span class="sr-only">
                                      {userCard.backSquat} KG
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <p className="d-flex flex-column text-right">
                                  <span className="font-weight-bold">
                                    {userCard.cleanAndJerk} KG
                                  </span>
                                  <span className="text-muted">
                                    CLEAN & JERK
                                  </span>
                                </p>
                                <div class="progress">
                                  <div
                                    class="progress-bar bg-danger"
                                    role="progressbar"
                                    aria-valuenow={
                                      userCard.cleanAndJerkPercentile
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="10"
                                    style={{
                                      width:
                                        userCard.cleanAndJerkPercentile * 10 +
                                        "%",
                                    }}
                                  >
                                    <span class="sr-only">
                                      {userCard.cleanAndJerk} KG
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <p className="d-flex flex-column text-right">
                                  <span className="font-weight-bold">
                                    {userCard.snatch} KG
                                  </span>
                                  <span className="text-muted">SNATCH</span>
                                </p>
                                <div class="progress">
                                  <div
                                    class="progress-bar bg-danger"
                                    role="progressbar"
                                    aria-valuenow={userCard.snatchPercentile}
                                    aria-valuemin="0"
                                    aria-valuemax="10"
                                    style={{
                                      width:
                                        userCard.snatchPercentile * 10 + "%",
                                    }}
                                  >
                                    <span class="sr-only">
                                      {userCard.snatch} KG
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <p className="d-flex flex-column text-right">
                                  <span className="font-weight-bold">
                                    {userCard.run5K} ''
                                  </span>
                                  <span className="text-muted">RUN 5K</span>
                                </p>
                                <div class="progress">
                                  <div
                                    class="progress-bar bg-danger"
                                    role="progressbar"
                                    aria-valuenow={userCard.run5KPercentile}
                                    aria-valuemin="0"
                                    aria-valuemax="10"
                                    style={{
                                      width:
                                        userCard.run5KPercentile * 10 + "%",
                                    }}
                                  >
                                    <span class="sr-only">
                                      {userCard.run5K} KG
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <h4>sin cromo</h4>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">TIMELINE</h3>
                      </div>
                      <div className="card-body">
                        <Timeline events={userActions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};

export default UserView;
