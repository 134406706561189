import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Table from "../../components/Common/Table";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { Link } from "react-router-dom";

const Comunications = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Comunicaciones", url: "/Comunications" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <Link to="/email">
                      <Widget
                        label="Envío de emails a capitanes, equipos y atletas"
                        value={"Emailing"}
                        icon="fas fa-envelope mr-1"
                        color={"bg-primary"}
                      />
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link to="/in-app">
                      <Widget
                        label="Envío de mensajes con notificación a la app"
                        value={"InApp + Push"}
                        icon="fas fa-comment mr-1"
                        color={"bg-danger"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
    </>
  );
};

export default Comunications;
