import { ApiUrlBase } from "../helpers/constants";

async function _getAll() {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
  };

  return fetch(ApiUrlBase + "/faqs", requestOptions);
}

async function _updateFaq(
  id,
  question,
  answer,
  category,
  keyWords
) {
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      question: question,
      answer: answer,
      category: category,
      keyWords: keyWords,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/faqs/" + id, requestOptions);
}

async function _createFaq(
  question,
  answer,
  category,
  keyWords
) {
  console.log("po");
  var apiSessionId = localStorage.getItem("apiSesionId");

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiSessionId,
    },
    body: JSON.stringify({
      question: question,
      answer: answer,
      category: category,
      keyWords: keyWords,
    }),
  };
  console.log(requestOptions.body);
  return fetch(ApiUrlBase + "/faqs/", requestOptions);
}


export const FAQsService = {
  getAll: _getAll,
  updateFaq: _updateFaq,
  createFaq: _createFaq,
};
