import { useNavigate } from "react-router-dom";
import { UrlBase } from "../../../helpers/constants";
import FixtureUserDetails from "../FixtureUserDetails";
import moment from "moment";
import Modal from "../Modal";
import EditMatchResult from "../../../screens/Fixture/EditResults/EditMatchResult";
import { useState } from "react";

const MatchDetails = ({ fixtureId, match, onUpdateMatch }) => {
  const navigate = useNavigate();

  const [showEditMatchScore, setShowEditMatchScore] = useState(false);

  return (
    <div className="card">
      <div className="card-header border-0">
        <h3 className="font-weight-light">
          {match.wod.gender2 ? "Dobles" : "Individual"}
          <a
            href="#"
            className="text-warning ml-4"
            onClick={() => setShowEditMatchScore(true)}
          >
            <i className="fas fa-edit"></i>
          </a>
        </h3>
      </div>
      <Modal
        body={<EditMatchResult match={match} fixtureId={fixtureId} />}
        show={showEditMatchScore}
        title={"Editar resultado"}
        handleClose={() => {
          setShowEditMatchScore(false);
          onUpdateMatch();
        }}
      />
      <div className="card-body">
        {/* Seccion resultados */}
        <div className="row border-bottom mb-3">
          <div className="col-md-6">
            <h2 className="font-weight-light mb-3 pb-2">
              {match.wod.qualifyType === 1
                ? moment.utc(match.homeResult * 1000).format("mm' ss''")
                : match.wod.qualifyType === 2
                ? `${match.homeResult ? match.homeResult : 0} REPS`
                : match.wod.qualifyType === 3
                ? `${match.homeResult ? match.homeResult : 0} KG`
                : ""}

              {match.wod.qualifyType2 === 1
                ? `& ${moment.utc(match.homeResult2 * 1000).format("mm' ss''")}`
                : match.wod.qualifyType2 === 2
                ? ` & ${match.homeResult2 ? match.homeResult2 : 0} REPS`
                : match.wod.qualifyType2 === 3
                ? ` & ${match.homeResult2 ? match.homeResult2 : 0} KG`
                : ""}
            </h2>
          </div>
          <div className="col-md-6">
            <h2 className="font-weight-light mb-3 pb-2 text-right">
              {match.wod.qualifyType === 1
                ? moment.utc(match.awayResult * 1000).format("mm' ss''")
                : match.wod.qualifyType === 2
                ? `${match.awayResult ? match.awayResult : 0} REPS`
                : match.wod.qualifyType === 3
                ? `${match.awayResult ? match.awayResult : 0} KG`
                : ""}

              {match.wod.qualifyType2 === 1
                ? `& ${moment.utc(match.awayResult2 * 1000).format("mm' ss''")}`
                : match.wod.qualifyType2 === 2
                ? ` & ${match.awayResult2 ? match.awayResult2 : 0} REPS`
                : match.wod.qualifyType2 === 3
                ? ` & ${match.awayResult2 ? match.awayResult2 : 0} KG`
                : ""}
            </h2>
          </div>
        </div>
        {/* Seccion wod details */}
        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
          <p className="text-danger text-xl">
            <i className="fas fa-venus-mars"></i>
          </p>
          <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
              {match.wod.gender2
                ? match.wod.gender1 !== match.wod.gender2
                  ? "Mixto"
                  : match.wod.gender1 === 1
                  ? "Masculino"
                  : "Femenino"
                : match.wod.gender1 === 1
                ? "Masculino"
                : "Femenino"}
            </span>
            <span className="text-muted">GÉNERO</span>
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
          <p className="text-primary text-xl">
            <i className="far fa-chart-bar"></i>
          </p>
          <p className="d-flex flex-column text-right">
            <span className="font-weight-bold">
              {match.wod.qualifyType === 1
                ? "For Time"
                : match.wod.qualifyType === 2
                ? "AMRAP"
                : "RM"}
              {match.wod.qualifyType2 === 1
                ? " THEN For Time"
                : match.wod.qualifyType2 === 2
                ? " THEN AMRAP"
                : match.wod.qualifyType2 === 3
                ? " THEN RM"
                : ""}
            </span>
            <span className="text-muted">CALIFICACIÓN</span>
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
          <p className="text-success text-xl">
            <i class="fas fa-dumbbell"></i>
          </p>
          <p className="d-flex flex-column text-right">
            <div className="card">
              <div className="card-header border-0">
                <h3 className="card-title">{match.wod.description}</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>

              <div className="card-body" style={{ display: "none" }}>
                {match.wod.fullDescription}
              </div>
            </div>
          </p>
        </div>
        {/* Seccion deportistas */}
        <h4 className="font-weight-light mb-3 pb-2">ATLETAS</h4>
        <div className="row border-bottom mb-3">
          {/* HOME */}
          <div className="col-md-6">
            <FixtureUserDetails user={match.homeUser1} wod={match.wod} />
            {match.wod.gender2 && (
              <FixtureUserDetails user={match.homeUser2} wod={match.wod} />
            )}
          </div>
          {/* AWAY */}
          <div className="col-md-6">
            <FixtureUserDetails
              user={match.awayUser1}
              wod={match.wod}
              isHome={false}
            />
            {match.wod.gender2 && (
              <FixtureUserDetails
                user={match.awayUser2}
                wod={match.wod}
                isHome={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MatchDetails;
