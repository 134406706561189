import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";

import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { TeamsService } from "../../services/teamsService";
import TeamEdit from "./TeamEdit";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Common/Table";

const Teams = () => {
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showTeamModal, setShowTeamModal] = useState(false);
  const [modalTeam, setModalTeam] = useState(null);
  const [isNewTeam, setIsNewTeam] = useState(false);

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getAllTeams = async () => {
    const response = await TeamsService.getAll();
    if (response.ok) {
      const result = await response.json();
      setTeams(result);
      setLoading(false);
    } else {
      return {
        success: false,
        message: "No se han podido obtener los equipos de base de datos.",
      };
    }
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  useEffect(() => {
    if (modalTeam) setShowTeamModal(true);
    else setShowTeamModal(false);
  }, [modalTeam]);

  useEffect(() => {
    if (teams.length > 0) initDataTables();
  }, [teams]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblTeams")) {
      window.$("#tblTeams").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2, 3],
            className: "text-center",
          },
        ],
      });
    }
  };

  const deleteTeam = async (id) => {
    // confirm if sure to delete
    const confirm = window.confirm(
      "¿Está seguro de eliminar el equipo? Esto es peligroso y no se puede deshacer. Si el equipo ha participado en competiciones, puedes perder datos importantes."
    );
    if (!confirm) return;

    const response = await TeamsService.deleteTeam(id);
    if (response.ok) {
      getAllTeams();
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {!loading > 0 ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Equipos", url: "/teams" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Equipos registrados"
                      value={teams.length}
                      icon="fas fa-dumbbell"
                    />
                  </div>
                  <div className="col-8"></div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Registros de Equipos</h3>
                      </div>
                      <div className="card-body">
                        <Table
                          data={teams}
                          fields={[
                            { titleField: "Nombre", objectField: "name" },
                            {
                              titleField: "Categoría",
                              objectField: "category.name",
                            },
                            {
                              titleField: "Capitán",
                              objectField: "captain.name",
                            },
                            { titleField: "Box", objectField: "box.name" },
                          ]}
                          actions={[
                            {
                              title: "Ver",
                              ico: "fas fa-eye",
                              class: "primary",
                              action: (team) => {
                                navigate("/equipo/" + team.id);
                              },
                            },
                            {
                              title: "Editar",
                              ico: "fas fa-edit",
                              class: "warning",
                              action: (team) => {
                                setModalTeam(team);
                                setIsNewTeam(false);
                              },
                            },
                            {
                              title: "Borrar",
                              ico: "fas fa-trash",
                              class: "danger",
                              action: (team) => {
                                deleteTeam(team.id);
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />

      <Modal
        show={showTeamModal}
        handleClose={() => {
          getAllTeams().then(() => {
            setModalTeam(null);
            setShowTeamModal(false);
          });
        }}
        body={
          modalTeam ? <TeamEdit team={modalTeam} newTeam={isNewTeam} /> : null
        }
        title={"Datos del team " + modalTeam?.name}
      />
    </>
  );
};

export default Teams;
