import { useEffect, useRef, useState } from "react";
import { GroupService } from "../../../services/groupService";
import { CategoriesService } from "../../../services/categoriesService";
import { TerritoriesService } from "../../../services/territoriesService";
import { DivisionsService } from "../../../services/divisionsService";
import { UsersService } from "../../../services/usersService";
import Table from "../../../components/Common/Table";
import { TeamsService } from "../../../services/teamsService";
import { MiscService } from "../../../services/miscService";
import Calendar from "../../../components/Common/Calendar";

const GroupsEdit = ({ group, newGroup }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(newGroup);
  const [isNewGroup, setIsNewGroup] = useState(newGroup);
  const switchEditRef = useRef(null);

  const [groupId, setGroupId] = useState(newGroup ? undefined : group.id);
  const [name, setTxtName] = useState(newGroup ? "" : group.name);
  const [category, setCategory] = useState(group.categoryId);
  const [territory, setTerritory] = useState(group.territoryId);
  const [division, setDivision] = useState(group.divisionId);

  const [groupTeams, setGroupTeams] = useState([]);
  const [teamsWithoutGroup, setTeamsWithoutGroup] = useState([]);
  const [showTeamsTable, setShowTeamsTable] = useState(false);

  const [categories, setCategories] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  //Calendario
  const [calendar, setCalendar] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  const getCalendar = async () => {
    const response = await GroupService.getGroupCalendar(groupId);
    const data = await response.json();
    setCalendar(data);
  };

  const deleteCalendar = async () => {
    const response = await GroupService.deleteCalendar(groupId);
    getCalendar();
    setShowCalendar(false);
  };

  const createCalendar = async () => {
    const response = await GroupService.createCalendar(groupId);
    getCalendar();
    setShowCalendar(true);
  };

  const swapFixture = async (fixtureId) => {
    const response = await GroupService.swapFixture(fixtureId);
    getCalendar();
  };

  useEffect(() => {
    getCalendar();
  }, []);

  const getCategories = async () => {
    const response = await CategoriesService.getAll();
    const data = await response.json();
    setCategories(data);
  };

  const getTerritories = async () => {
    const response = await TerritoriesService.getAll();
    const data = await response.json();
    setTerritories(data);
  };

  const getDivisions = async () => {
    const response = await DivisionsService.getAll();
    const data = await response.json();
    setDivisions(data);
  };

  const getGroupTeams = async () => {
    const response = await GroupService.getGroupTeams(groupId);
    const data = await response.json();
    setGroupTeams(data);
  };

  const getTeamsWithoutGroup = async () => {
    const response = await TeamsService.getTeamsWithoutGroup();
    const data = await response.json();
    const mappedData = data.map((team) => {
      const boxProvince = team.box
        ? provinces.find((p) => p.id === team.box.provinceId)
        : provinces.find((p) => p.id === team.captain.provinceId);
      const boxCity = team.box
        ? cities.find((c) => c.id === team.box.cityId)
        : cities.find((p) => p.id === team.captain.provinceId);

      return {
        ...team,
        province: boxProvince ? boxProvince.name : null,
        city: boxCity ? boxCity.name : null,
      };
    });
    setTeamsWithoutGroup(mappedData);
  };

  const getProvinces = async () => {
    const response = await MiscService.getAllProvinces();
    const data = await response.json();
    setProvinces(data);
  };

  const getCities = async () => {
    const response = await MiscService.getAllCities();
    const data = await response.json();
    setCities(data);
  };

  useEffect(() => {
    // call all functions and wait to complete all
    Promise.all([
      getCategories(),
      getTerritories(),
      getDivisions(),
      getGroupTeams(),
      getProvinces(),
      getCities(),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (provinces.length > 0 && cities.length > 0) {
      getTeamsWithoutGroup();
    }
  }, [provinces, cities]);

  const validateName = () => {
    if (name != undefined) {
      if (name.length === 0) {
        return "El Nombre es obligatorio.";
      } else if (name.length > 512) {
        return "El Nombre debe tener menos de 512 caracteres.";
      } else {
        return null;
      }
    } else return "La pregunta es obligatoria.";
  };

  const validateCategory = () => {
    if (category == "" || category == undefined) {
      return "La categoría es obligatoria.";
    } else {
      return null;
    }
  };

  const validateTerritory = () => {
    if (territory == "" || territory == undefined) {
      return "El territorio es obligatorio.";
    } else {
      return null;
    }
  };

  const validateDivision = () => {
    if (division == "" || division == undefined) {
      return "La división es obligatoria.";
    } else {
      return null;
    }
  };

  const validateAll = () => {
    const formErrors = {
      name: validateName(),
      category: validateCategory(),
      territory: validateTerritory(),
      division: validateDivision(),
    };

    return formErrors;
  };

  const addGroupTeam = async (team) => {
    const response = await GroupService.addGroupTeam(groupId, team.id);
    const data = await response.json();
    setGroupTeams(data);
    getTeamsWithoutGroup().then(() => {
      setShowTeamsTable(false);
    });
  };

  const deleteGroupTeam = async (team) => {
    const response = await GroupService.deleteGroupTeam(groupId, team.id);
    const data = await response.json();
    setGroupTeams(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateAll();
    setErrors(errors);
    if (Object.values(errors).every((x) => x === null)) {
      if (isNewGroup) {
        //_createGroup(categoryId, divisionId, name, territoryId)
        const createGroup = async () => {
          const response = await GroupService.createGroup(
            category,
            division,
            name,
            territory
          );

          if (response.ok) {
            const createdGroup = await response.json();
            setGroupId(createdGroup.id);
            setEdit(false);
            setIsNewGroup(false);
          } else {
            // show error message
          }
        };
        createGroup();
      } else {
        //_updateGroup(id, categoryId, divisionId, name, territoryId)
        const updateGroup = async () => {
          const response = await GroupService.updateGroup(
            groupId,
            category,
            division,
            name,
            territory
          );

          if (response.ok) {
            switchEditRef.current.checked = false;
            setEdit(false);
            // show success message
          } else {
            // show error message
          }
        };
        updateGroup();
      }
    } else {
      console.log(errors);
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (showCalendar) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Calendario de {group.name}</h3>
                </div>
                <div className="card-body">
                  {calendar.map((vs) => (
                    <>
                      <Calendar
                        week={vs.week}
                        date={vs.date}
                        homeTeamName={vs.homeTeam.name}
                        awayTeamName={vs.awayTeam.name}
                      />
                      <div style={{ textAlign: "center" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => swapFixture(vs.id)}
                        >
                          Intercambiar Equipos
                        </button>
                      </div>
                      <hr />
                    </>
                  ))}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => deleteCalendar()}
                  >
                    Resetear Calendario
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowCalendar(false)}
          >
            Volver
          </button>
        </div>
      </>
    );
  }

  if (showTeamsTable) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div className="card-header">
                  <h3 className="card-title">Añadir equipo a grupo</h3>
                </div>
                <div className="card-body">
                  <Table
                    data={teamsWithoutGroup}
                    fields={[
                      { objectField: "name", titleField: "Nombre" },
                      { objectField: "category.name", titleField: "Categoría" },
                      {
                        objectField: "province",
                        titleField: "Provincia",
                      },
                      { objectField: "city", titleField: "Ciudad" },
                    ]}
                    actions={[
                      {
                        title: "Seleccionar",
                        ico: "fas fa-solid fa-check",
                        class: "success",

                        action: (obj) => {
                          addGroupTeam(obj);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {isNewGroup ? null : (
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                value={edit}
                ref={switchEditRef}
                onChange={(e) => setEdit(e.target.checked)}
                class="custom-control-input"
                id="edit"
              />
              <label class="custom-control-label" for="edit">
                Activar el modo edición
              </label>
            </div>
          </div>
        )}
        <form>
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="name">Nombre</label>
                  <input
                    type="text"
                    className={
                      (errors.name ? "is-invalid " : "") + "form-control"
                    }
                    id="name"
                    value={name}
                    onChange={(e) => setTxtName(e.target.value)}
                    disabled={!edit}
                  />
                  <span className="invalid-feedback">{errors.name}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="categoyid">Categoría</label>
                  <select
                    className={
                      (errors.category ? "is-invalid " : "") + "form-control"
                    }
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="">-- Selecciona una categoría --</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.category}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="territoryid">Territorio</label>
                  <select
                    className={
                      (errors.territory ? "is-invalid " : "") + "form-control"
                    }
                    id="territory"
                    value={territory}
                    onChange={(e) => setTerritory(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="">-- Selecciona un territorio --</option>
                    {territories.map((territory) => (
                      <option key={territory.id} value={territory.id}>
                        {territory.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.territory}</span>
                </div>
                <div className="form-group">
                  <label htmlFor="divisionid">División</label>
                  <select
                    className={
                      (errors.division ? "is-invalid " : "") + "form-control"
                    }
                    id="division"
                    value={division}
                    onChange={(e) => setDivision(e.target.value)}
                    disabled={!edit}
                  >
                    <option value="">-- Selecciona una división --</option>
                    {divisions.map((division) => (
                      <option key={division.id} value={division.id}>
                        {division.name}
                      </option>
                    ))}
                  </select>
                  <span className="invalid-feedback">{errors.division}</span>
                </div>
                {isNewGroup ? null : (
                  <>
                    <div class="row">
                      <div class="col-12">
                        <div class="card card-info">
                          <div class="card-header">
                            <span>Equipos</span>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm float-right"
                              onClick={() => setShowTeamsTable(true)}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div>

                          <div class="card-body">
                            {groupTeams.length > 0
                              ? groupTeams.map((team) => (
                                  <>
                                    <p>
                                      {team.name}
                                      <button
                                        type="button"
                                        class="btn btn-danger btn-sm float-right"
                                        onClick={() => deleteGroupTeam(team)}
                                      >
                                        <i className="fas fa-minus"></i>
                                      </button>
                                    </p>
                                  </>
                                ))
                              : null}
                          </div>
                          {edit ? null : <div class="overlay"></div>}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        {calendar.length !== 0 ? (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => setShowCalendar(true)}
                            disabled={!edit}
                          >
                            Mostrar Calendario
                          </button>
                        ) : groupTeams.length > 2 ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => createCalendar()}
                            disabled={!edit}
                          >
                            Generar Calendario
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {edit ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default GroupsEdit;
