import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Layout/Loader";
import Alert from "../../../components/Common/Alert";
import { SubscriptionRequestService } from "../../../services/subscriptionRequestService";

const SubscriptionRequestEdit = ({ subscriptionRequest }) => {
  const [loading, setLoading] = useState(true);

  var AlertBody = "";
  var AlertType = "";
  var AlertTitle = "";
  var AlertIcon = "";
  var Price = subscriptionRequest.priceOffer;

  switch (subscriptionRequest.paymentStatus) {
    case 1:
      AlertTitle = "No pagado";
      AlertBody = "PENDIENTE DE PAGO";
      AlertType = "warning";
      AlertIcon = "fas fa-credit-card";
      break;
    case 2:
      AlertTitle = "Pagado";
      AlertBody = "PAGO COMPLETADO";
      AlertType = "success";
      AlertIcon = "fas fa-credit-card";
      break;
    case 3:
      AlertTitle = "No pagado";
      AlertBody = "PAGO RECHAZADO";
      AlertType = "danger";
      AlertIcon = "fas fa-credit-card";
      break;
  }
  return (
    <div className="row">
      <div className="col-md-12">
        {/* ------------------------------ Alert Estado Pago ------------------------------------------------------ */}

        <Alert
          title={AlertTitle}
          body={"Estado del Pago:  " + AlertBody}
          canClose={false}
          type={AlertType}
          icon={AlertIcon}
        />
        {/* ------------------------------ Datos del pago ------------------------------------------------------ */}
        {subscriptionRequest.paymentStatus == 2 ? (
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Datos del Pago</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="teamName">Codigo de Desccuento usado</label>
                    <input
                      type="text"
                      className="form-control"
                      id="PrpmotionalCode"
                      value={Price.code}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="teamName">
                      Descripcion Código descuento
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PrpmotionalCode"
                      value={Price.description}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="teamName">Precio Pagado</label>
                    <input
                      type="text"
                      className="form-control"
                      id="PrpmotionalCode"
                      value={Price.price / 100 + " €"}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* ------------------------------ Datos de Equipo ------------------------------------------------------ */}
        <div className="row">
          <div className="col-md-12">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Datos del Equipo</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="teamName">Nombre del equipo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="teamName"
                    value={subscriptionRequest.teamName}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------ Datos del Capitán ------------------------------------------------------ */}
        <div className="row">
          <div className="col-md-12">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Datos del Capitán</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="captainName">Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainName"
                    value={subscriptionRequest.name}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainSurname">Apellidos</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainSurname"
                    value={subscriptionRequest.surname}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainEmail">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainEmail"
                    value={subscriptionRequest.email}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainPersonalId">
                    Identificación personal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainPersonalId"
                    value={subscriptionRequest.personalId}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainPhone">Teléfono</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainPhone"
                    value={subscriptionRequest.phoneNumber}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainAddressName">Dirección</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainAddressName"
                    value={
                      subscriptionRequest.addressName +
                      ", " +
                      subscriptionRequest.addressNumber
                    }
                    disabled
                  />
                </div>
                {subscriptionRequest.addressOther !== "" ? (
                  <div className="form-group">
                    <label htmlFor="captainAddressOther">
                      Otros datos de dirección
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="captainAddressOther"
                      value={subscriptionRequest.addressOther}
                      disabled
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="captainPostalCode">Código Postal</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainPostalCode"
                    value={subscriptionRequest.zipCode}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainCity">Ciudad</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainCity"
                    value={subscriptionRequest.cityId}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="captainProvince">Provincia</label>
                  <input
                    type="text"
                    className="form-control"
                    id="captainProvince"
                    value={subscriptionRequest.provinceId}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------------ Datos del Box ------------------------------------------------------ */}
        {subscriptionRequest.teamHasBox ? (
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Datos del Box</h3>
                </div>
                <div className="card-body">
                  {subscriptionRequest.boxId ? (
                    <>
                      <div className="form-group">
                        <label htmlFor="boxAddress">Dirección</label>
                        <input
                          type="text"
                          className="form-control"
                          id="boxAddress"
                          value={
                            subscriptionRequest.box.addressName +
                            ", " +
                            subscriptionRequest.box.addressNumber
                          }
                          disabled
                        />
                      </div>
                      {subscriptionRequest.box.addressOther !== "" ? (
                        <div className="form-group">
                          <label htmlFor="boxAddressOther">
                            Otros datos de dirección
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="boxAddressOther"
                            value={subscriptionRequest.box.addressOther}
                            disabled
                          />
                        </div>
                      ) : null}

                      <div className="form-group">
                        <label htmlFor="boxEmail">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="boxEmail"
                          value={subscriptionRequest.box.email}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="boxPhoneNumber">Teléfono</label>
                        <input
                          type="text"
                          className="form-control"
                          id="boxPhoneNumber"
                          value={subscriptionRequest.box.phone}
                          disabled
                        />
                      </div>
                    </>
                  ) : (
                    <h3>El equipo ha usado un Box ya verificado</h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubscriptionRequestEdit;
