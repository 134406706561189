import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal/Modal";
import Title from "../../components/Common/Title/Title";
import Widget from "../../components/Common/Widget";
import Table from "../../components/Common/Table";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Settings from "../../components/Layout/Settings";
import Sidebar from "../../components/Layout/Sidebar";
import Loader from "../../components/Layout/Loader";
import { WodService } from "../../services/wodService";
import WodEdit from "./WodEdit";

const Wods = () => {
  const [wods, setWods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showWodModal, setShowWodModal] = useState(false);
  const [modalWod, setModalWod] = useState(null);
  const [isNewWod, setIsNewWod] = useState(false);
  const [titleModal, setTitleModal] = useState("Editar wod");

  useEffect(() => {
    document.body.className = "sidebar-mini layout-fixed";
  }, []);

  const getWods = async () => {
    const response = await WodService.getAll();
    if (response.ok) {
      const result = await response.json();
      setWods(result);
      setIsLoading(false);
    } else {
      return {
        success: false,
        message: "No se han podido obtener los Wods de base de datos.",
      };
    }
  };

  useEffect(() => {
    getWods();
  }, []);

  useEffect(() => {
    if (modalWod) {
      setShowWodModal(true);
    } else {
      setShowWodModal(false);
    }
  }, [modalWod]);

  useEffect(() => {
    if (wods.length > 0) initDataTables();
  }, [wods]);

  const initDataTables = () => {
    if (!window.$.fn.dataTable.isDataTable("#tblWods")) {
      window.$("#tblWods").DataTable({
        responsive: true,
        autoWidth: false,
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
        columnDefs: [
          {
            targets: [0, 1, 2],
            className: "text-center",
          },
        ],
      });
    }
  };

  const deleteWod = async (wod) => {
    // confirm if sure to delete
    const confirm = window.confirm(
      '¿Está seguro de eliminar el wod "' + wod.description + '"?'
    );
    if (!confirm) return;

    const response = await WodService.deleteWod(wod.id);
    if (response.ok) {
      getWods();
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {!isLoading ? (
        <>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <Title
                    items={[
                      { title: "Home", url: "/" },
                      { title: "Competición", url: "#" },
                      { title: "Wods", url: "/wods" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <Widget
                      label="Wods"
                      value={wods.length}
                      icon="fas fa-question mr-1"
                    />
                  </div>
                  <div className="col-8"></div>
                  <div className="col-1 text-right">
                    <button
                      class="btn btn-app bg-info mt-5"
                      onClick={() => {
                        setModalWod({});
                        setIsNewWod(true);
                        setTitleModal("Nuevo Wod");
                      }}
                    >
                      <i class="fas fa-plus"></i> Agregar
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card card-info">
                      <div className="card-header">
                        <h3 className="card-title">Lista de wods</h3>
                      </div>
                      <div className="card-body">
                        <Table
                          data={wods}
                          fields={[
                            {
                              objectField: "description",
                              titleField: "Nombre",
                            },
                            {
                              objectField: "fullDescription",
                              titleField: "Descripcion",
                            },
                          ]}
                          actions={[
                            {
                              title: "Editar",
                              ico: "fas fa-edit",
                              class: "warning",
                              action: (obj) => {
                                setModalWod(obj);
                                setIsNewWod(false);
                                setTitleModal("Editar wod");
                              },
                            },
                            {
                              title: "Borrar",
                              ico: "fas fa-trash",
                              class: "danger",
                              action: (obj) => {
                                deleteWod(obj);
                              },
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
      <Settings />
      <Modal
        show={showWodModal}
        handleClose={() => {
          setModalWod(null);
          setShowWodModal(false);
          getWods();
        }}
        body={modalWod ? <WodEdit wod={modalWod} newWod={isNewWod} /> : null}
        title={titleModal}
      />
    </>
  );
};

export default Wods;
