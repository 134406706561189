import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
  return (
    <ol className="breadcrumb float-sm-right">
      {items.map((item, index) => {
        if (index == items.length - 1) {
          return (
            <li className="breadcrumb-item active" key={index}>
              {item.title}
            </li>
          );
        } else {
          return (
            <li className="breadcrumb-item" key={index}>
              <Link to={item.link}>{item.title}</Link>
            </li>
          );
        }
      })}
    </ol>
  );
};

export default Breadcrumbs;
